import { Injectable } from '@angular/core';

import { IInstrument, TradePrint } from 'trading';

import { IFeedRelayConfig } from './feed-relay.service';
import { RealFeed, WSMessageTypes } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealTradeDataFeed extends RealFeed<TradePrint, IInstrument> {
  type = RealtimeType.TradePrint;
  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;
  get useFeedRelay(): boolean {
    return true;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: 30,
    };
  }
}
