import { Column, ColumnSortDirectionEnum } from '../../components/types';
import { Cell, ICellConfig } from './cell';

interface ISortableCellConfig extends ICellConfig {
  sortDirection?: ColumnSortDirectionEnum;
  size?: number;
}

export class SortableCell extends Cell {
  private readonly rectOffset = 2.2;
  private readonly minWidthFromEdge = 4;
  private x: number;
  private y: number;
  private _size: number;
  private _showColumnPanel = true;
  private _sortDirection: ColumnSortDirectionEnum;

  set showColumnPanel(value: boolean) {
    this._showColumnPanel = value;
  }

  get sortDirection(): ColumnSortDirectionEnum {
    return this._sortDirection;
  }

  set sortDirection(value: ColumnSortDirectionEnum) {
    this._sortDirection = value;
  }

  constructor(config?: ISortableCellConfig) {
    super(config);
    this._size = config?.size ?? 10;
  }

  updateValue(): void {}

  draw(context) {
    const ctx = context?.ctx;

    if (!ctx) return;

    const { column, grid }: { column: Column; grid: any } = context; // TODO add ts.d for canvas-grid and remove `any` refs
    // const prevStrokeStyle = ctx.strokeStyle;
    ctx.save();
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';

    this.y = context.y + context.height / 2 - this._size / 2;
    this.x = context.x;

    // Draw title
    const columnWidth = column.width;
    const textWidth = grid.getTextWidth(column.title);

    let rowHeight = grid.getHeaderHeight();
    const title = this.fittingString(
      ctx,
      column.title || column.name,
      columnWidth,
    );
    let textX = this.x + columnWidth / 2;
    let textY = rowHeight / 2;
    ctx.fillText(title, textX, textY);

    if (context.y === 0 && this._showColumnPanel) {
      // Cell border
      ctx.strokeStyle = '#24262C';
      ctx.strokeRect(context.x, context.y, context.width, context.height);
      // ctx.strokeStyle = prevStrokeStyle;

      // Up/Down Arrows
      const iconSize = 10;
      const iconX = textX + textWidth / 2;
      const iconY = textY - iconSize / 2 - 1;

      if (column.sortDirection === ColumnSortDirectionEnum.ASC) {
        this.drawArrowUp(context, column, iconX, iconY, iconSize);
      }
      if (column.sortDirection === ColumnSortDirectionEnum.DESC) {
        this.drawArrowDown(context, column, iconX, iconY, iconSize);
      }

      ctx.strokeStyle = '#51535A';
    }

    return true;
  }

  drawArrowDown(context, column, x, y, buttonSize = 10) {
    const ctx = context?.ctx;

    // Draw icon box
    // ctx.save();
    // ctx.beginPath();
    // ctx.fillStyle = 'rgba(36,38,66,1)';
    // ctx.fillRect(x, y, buttonSize, buttonSize);
    // ctx.restore();

    // Draw arrow pointing down
    ctx.beginPath();
    ctx.strokeStyle = 'rgb(112,112,112)';
    const startX = x + 3;
    const startY = y + 5;
    ctx.moveTo(startX, startY);
    ctx.lineTo(x + buttonSize / 2, y + 2 + buttonSize / 2);
    ctx.stroke();
    ctx.lineTo(x + buttonSize - 3, startY);
    ctx.stroke();
  }

  drawArrowUp(context, column, x, y, buttonSize = 10) {
    const ctx = context.ctx;

    // Draw icon box
    // ctx.save();
    // ctx.beginPath();
    // ctx.fillStyle = 'rgba(36,38,44,1)';
    // ctx.fillRect(x, y, buttonSize, buttonSize);
    // ctx.restore();

    // Draw arrow pointing up
    ctx.beginPath();
    ctx.strokeStyle = 'rgb(112,112,112)';
    const startX = x + 3;
    const startY = y + buttonSize - 5;
    ctx.moveTo(startX, startY);
    ctx.lineTo(x + buttonSize / 2, y - 2 + buttonSize / 2);
    ctx.stroke();
    ctx.lineTo(x + buttonSize - 3, startY);
    ctx.stroke();
  }

  fittingString(ctx, str, maxWidth) {
    let width = ctx.measureText(str).width;
    const ellipsis = ' …';
    const ellipsisWidth = ctx.measureText(ellipsis).width;

    if (width <= maxWidth || width <= ellipsisWidth) {
      return str;
    } else {
      let len = str.length;
      while (width >= maxWidth - ellipsisWidth && len-- > 0) {
        str = str.substring(0, len);
        width = ctx.measureText(str).width;
      }
      return str + ellipsis;
    }
  }
}
