import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

import { Id } from 'communication';
import { Broker, ConnectionsRepository, IConnection } from 'trading';

function shouldExist(object: any, key: string) {
  if (object[key] == null || object[key] === '') return `${key} should exist`;

  return null;
}

export class Connection implements IConnection {
  broker: Broker;
  name: string;
  username: string;
  password?: string;
  server: string;
  aggregatedQuotes: boolean;
  gateway: string;
  autoSavePassword: boolean;
  connectOnStartUp: boolean;
  useHybridInfraMode: boolean = true;
  useSimulator = false;
  private _connected: boolean;

  public get connected(): boolean {
    return this._connected;
  }

  public set connected(value: boolean) {
    if (value) this.err = null;

    this._connected = value;
  }

  private _isLoggedInWithBroker = false;

  public get isLoggedInWithBroker(): boolean {
    return this._isLoggedInWithBroker;
  }

  public set isLoggedInWithBroker(value: boolean) {
    this._isLoggedInWithBroker = value;
  }

  favorite: boolean;
  isDefault: boolean;

  err?: any;
  connectionData: any;
  id: Id;

  get needCreate() {
    return this.id == null;
  }

  get error(): boolean {
    return this.err != null;
  }

  get canConnectOnStartUp() {
    return this.connectOnStartUp && this.password && this.password !== '';
  }

  private _loadingCount = 0;

  get loading(): boolean {
    return this._loadingCount > 0;
  }

  constructor(
    private _connectionsRepository: ConnectionsRepository,
    private _onChange: () => void,
  ) {}

  makeDefault(isDefault = true): any {
    return this.update({ isDefault });
  }

  toggleFavorite(): Observable<any> {
    return this.update({ favorite: !this.favorite });
  }

  update(value: any): Observable<any> {
    return this._connectionsRepository
      .updateItem({ ...this.toJson(), ...value })
      .pipe(
        tap((res) => {
          const { metadata, ...data } = res as any;
          this.applyJson(data);
          this.applyJson(metadata);
        }),
        map(() => null),
      );
  }

  create(): Observable<any> {
    if (!this.name) {
      this.name = `${this.server}(${this.gateway})`;
    }

    const errors = [
      shouldExist(this, 'username'),
      // shouldExist(this, 'password'),
      shouldExist(this, 'server'),
      shouldExist(this, 'broker'),
      shouldExist(this, 'gateway'),
    ]
      .filter(Boolean)
      .join(', ');

    if (errors.length) {
      this.err = errors;
      return throwError({ message: `To create connection ${errors}` });
    }

    return this._connectionsRepository.createItem(this.toJson()).pipe(
      tap((res) => this.applyJson(res)),
      map(() => null),
    );
  }

  rename(name: string): Observable<any> {
    return this.update({ name });
  }

  remove(): Observable<any> {
    return this._connectionsRepository
      .deleteItem(this.id)
      .pipe(map(() => null));
  }

  connect(makeDefault: boolean = false): Observable<this> {
    const fn = this._makeLoading();

    this.isDefault = makeDefault;

    const connection = this.toJson();
    return this._connectionsRepository.connect(connection).pipe(
      tap((item) => {
        this._handleConnection(item);
      }),
      mergeMap(() => this.update({})),
      map(() => this),
      finalize(fn),
    );
  }

  disconnect(): Observable<void> {
    if (!this.connected || this.loading) return of();

    const fn = this._makeLoading();
    const connection = this.toJson();
    return this._connectionsRepository.disconnect(connection).pipe(
      finalize(fn),
      // concatMap(() => this.updateItem(updatedConnection)),
      // tap(() => {
      //   // this._onDisconnected(connection);
      //   if (connection.error)
      //     this._notificationService.showError(connection.err, 'Connection is closed');
      //   else
      //     this._notificationService.showSuccess('Connection is closed');
      // }),
      catchError((err: HttpErrorResponse) => {
        const disconnectedConnection = {
          ...connection,
          connected: false,
          err: null,
        };
        const message = err.message.toLowerCase();
        if (
          message.includes('no connection') ||
          message.includes('invalid api key')
        )
          return of(disconnectedConnection);
        // this._onDisconnected(connection);

        if (err.status === 401) {
          // this.onUpdated(updatedConnection);
          // this._onDisconnected(updatedConnection);
          return of(disconnectedConnection);
        } else return throwError(err);
      }),
      tap((item) => {
        this._handleConnection(item);
      }),
    );
  }

  markAsOnline() {
    if (this.connected) {
      return;
    }

    this._loadingCount--;
    this.connected = true;
    this._trackChanges();
  }

  markAsOffline() {
    if (!this.connected || this.loading) {
      return;
    }

    this._makeLoading();
    this.connected = false;
    this._trackChanges();
  }

  private _trackChanges() {
    if (this._onChange) this._onChange();
  }

  private _handleConnection(item) {
    this.err = item.err;
    this.connected = item.connected;
    this.isLoggedInWithBroker = item.isLoggedIn;
    this.connectionData = item.connectionData;
    this._trackChanges();
  }

  private _makeLoading(): () => void {
    this._loadingCount++;
    this._trackChanges();
    return () => {
      this._loadingCount--;
    };
  }

  fromJson(json: any): this {
    if (json == null) return;

    delete json.error;
    delete json.loading;
    delete json.connected;
    //  delete json.connectOnStartUp;
    // delete json.err;
    // if (json.connected)
    //   delete json.err;
    this.applyJson(json);

    return this;
  }

  applyJson(json: any) {
    if (json == null) return;

    Object.assign(this, json);
    this._trackChanges();
  }

  toJson(): any {
    return {
      broker: this.broker,
      name: this.name,
      username: this.username,
      password: this.password,
      server: this.server,
      aggregatedQuotes: this.aggregatedQuotes,
      gateway: this.gateway,
      autoSavePassword: this.autoSavePassword,
      connectOnStartUp: this.connectOnStartUp,
      useHybridInfraMode: this.useHybridInfraMode,
      useSimulator: this.useSimulator,
      connected: this.connected,
      favorite: this.favorite,
      isDefault: this.isDefault,
      err: this.err,
      connectionData: this.connectionData,
      id: this.id,
    };
  }

  destroy() {
    delete this._onChange;
  }

  destroyIfNew() {
    if (!this.needCreate) return;

    this.destroy();
  }
}
