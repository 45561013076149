import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentStore } from 'lazy-modules';
import { NzButtonModule } from 'ng-zorro-antd';
import { WindowHeaderModule } from 'window-header';

import { PerformanceMetricsToolbarComponent } from './performance-metrics-toolbar/performance-metrics-toolbar.component';
import { PerformanceMetricsComponent } from './performance-metrics/performance-metrics.component';
import { PerformanceToolsComponent } from './performance-tools.component';

@NgModule({
  declarations: [
    PerformanceToolsComponent,
    PerformanceMetricsToolbarComponent,
    PerformanceMetricsComponent,
  ],
  imports: [CommonModule, NzButtonModule, WindowHeaderModule],
  exports: [
    PerformanceToolsComponent,
    PerformanceMetricsToolbarComponent,
    PerformanceMetricsComponent,
  ],
})
export class PerformanceToolsModule {
  get components(): ComponentStore {
    return {
      'performance-tools': PerformanceToolsComponent,
    };
  }
}
