import { Inject, Injectable, Injector } from '@angular/core';

import { AccountsManager } from 'accounts-manager';
import { WEB_SOCKET_TYPE } from 'communication';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { IOrder } from 'trading';

import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealOrdersFeed extends RealFeed<IOrder> {
  type = RealtimeType.Order;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RAPI;
  }

  get useFeedRelay(): boolean {
    return true;
  }

  protected _map(item: IOrder): any {
    item.description = item.instrument.description ?? '';

    return super._map(item);
  }
}
