import { Component, OnInit } from '@angular/core';

import { ILayoutNode, IStateProvider, LayoutNode } from 'layout';

export interface PerformanceToolsComponent extends ILayoutNode {}

interface IPerformanceToolsState {}

@Component({
  selector: 'lib-performance-tools',
  templateUrl: './performance-tools.component.html',
  styleUrls: ['./performance-tools.component.scss'],
})
@LayoutNode()
export class PerformanceToolsComponent
  implements OnInit, IStateProvider<IPerformanceToolsState>
{
  constructor() {
    this.setTabIcon('icon-solidgram');
    this.setTabTitle('Perf Tools');
  }

  ngOnInit(): void {}

  saveState(): IPerformanceToolsState {
    const savedTime: string = new Date().toISOString();
    return {
      savedTime,
    };
  }
  loadState(state: IPerformanceToolsState): void {
    // do nothing for now
  }
}
