import { IConnection } from 'projects/trading/src/trading/models/connection';

interface RProtocolEndpointMap {
  [key: string]: { [key: string]: string };
}

export class RProtocolEndpointMapper {
  /**
   * Returns the endpoint for the specified connection
   * @param connection
   */
  public static getEndpointForConnection(
    connection: Partial<IConnection>,
  ): string {
    const { server, gateway } = connection;
    const endpoints: RProtocolEndpointMap = RProtocolEndpointMapper.getMap();
    if (!endpoints[server]) {
      throw new Error(`Unknown server: ${server} (gateway: ${gateway})`);
    }
    const fallbackServerName: string = 'Rithmic Paper Trading';
    const fallbackGatewayName: string = 'Chicago Area';

    let supportedEndpoints: { [key: string]: string } = endpoints[server];
    let supportedGatewayNames: string[] = Object.keys(supportedEndpoints);
    const fallbackEndpoints: { [key: string]: string } =
      endpoints[fallbackServerName];

    // If the server has 1 supported gateway only, Chicago Area, and its endpoint
    // is the main one, all other gateways under "Rithmic Paper Trading" server are also
    // supported for the specified server
    if (
      supportedGatewayNames.length === 1 &&
      supportedGatewayNames[0] === fallbackGatewayName &&
      endpoints[server][fallbackGatewayName] ===
        fallbackEndpoints[fallbackGatewayName]
    ) {
      supportedGatewayNames = Object.keys(fallbackEndpoints);
      supportedEndpoints = fallbackEndpoints;
    }
    if (!supportedGatewayNames.includes(gateway)) {
      throw new Error(`Unknown gateway: ${gateway} (server: ${server})`);
    }
    return supportedEndpoints[gateway];
  }

  public static mainUrl(): string {
    return 'wss://rprotocol-beta.rithmic.com:443';
    // return 'wss://rprotocol-mobile.rithmic.com:443'; // TODO switch to this endpoint after Conformance
  }

  public static getMap(): RProtocolEndpointMap {
    const mainUrl: string = RProtocolEndpointMapper.mainUrl();

    return {
      'Rithmic Dev': {
        Orangeburg: 'wss://rittz00100.rithmic.com:443',
        'Orangeburg (test)': 'ws://rittz00100.rithmic.com:40056',
      },
      'Rithmic Test': {
        Orangeburg: 'wss://rituz00100.rithmic.com',
      },
      'Rithmic Paper Trading': {
        'Chicago Area': mainUrl,
        Seoul: 'wss://rprotocol-kr.rithmic.com:443',
        Mumbai: 'wss://rprotocol-in.rithmic.com:443',
        'Hong Kong': 'wss://rprotocol-hk.rithmic.com:443',
        'Sao Paolo': 'wss://rprotocol-br.rithmic.com:443',
        Singapore: 'wss://rprotocol-sg.rithmic.com:443',
        Sydney: 'wss://rprotocol-au.rithmic.com:443',
        Europe: 'wss://rprotocol-ie.rithmic.com:443',
        Tokyo: 'wss://rprotocol-jp.rithmic.com:443',
        Frankfurt: 'wss://rprotocol-de.rithmic.com:443',
      },
      'Rithmic Paper Trading (beta)': {
        'Chicago Area': 'wss://rprotocol-beta.rithmic.com:443',
      },
      'Rithmic 01': {
        'Chicago Area': mainUrl,
        Seoul: 'wss://rprotocol-kr.rithmic.com:443',
        Mumbai: 'wss://rprotocol-in.rithmic.com:443',
        'Hong Kong': 'wss://rprotocol-hk.rithmic.com:443',
        'Sao Paolo': 'wss://rprotocol-br.rithmic.com:443',
        Singapore: 'wss://rprotocol-sg.rithmic.com:443',
        Sydney: 'wss://rprotocol-au.rithmic.com:443',
        Europe: 'wss://rprotocol-ie.rithmic.com:443',
        Tokyo: 'wss://rprotocol-jp.rithmic.com:443',
      },
      'Rithmic 04 Colo': {
        'Chicago Area': mainUrl,
      },
      TopstepTrader: {
        'Chicago Area': mainUrl,
      },
      SpeedUp: {
        'Chicago Area': mainUrl,
      },
      TradeFundrr: {
        'Chicago Area': mainUrl,
      },
      UProfitTrader: {
        'Chicago Area': mainUrl,
      },
      Apex: {
        'Chicago Area': mainUrl,
      },
      'Leeloo Trading': {
        'Chicago Area': mainUrl,
      },
      'MES Capital': {
        'Chicago Area': mainUrl,
      },
      FundedFuturesNetwork: {
        'Chicago Area': mainUrl,
      },
      TheTradingPit: {
        'Chicago Area': mainUrl,
      },
      Bulenox: {
        'Chicago Area': mainUrl,
      },
      'Neurostreet Funding': {
        'Chicago Area': mainUrl,
      },
    } as RProtocolEndpointMap;
  }
}
