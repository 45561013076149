import { Injectable } from '@angular/core';

import { DataLevel, IInstrument, IQuote } from 'trading';

import { RealFeed, WSMessageTypes } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealLevel2DataFeed extends RealFeed<IQuote, IInstrument> {
  type = RealtimeType.Quote;
  subscribeType = WSMessageTypes.SUBSCRIBE_L2;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE_L2;

  protected _filter(item: IQuote): boolean {
    return item.level === DataLevel.Level2;
  }
}
