import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';

import { IInstrument, IOrder, OrderAccount } from 'trading';

import { rti } from './messages-js/otps_proto_pool';
import { RProtocolConnectionWebSocketService } from './rprotocol-connection.web-socket.service';

/**
 * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
 * @see {@link https://tradrr.atlassian.net/wiki/spaces/TRADRR/pages/60489729/R+Protocol+-+Order+Plant+-+Requests+Responses+and+Relationships|R Protocol - Order Plant - Requests, Responses and Relationships}
 */
@Injectable({
  providedIn: 'root',
})
export class RProtocolOrderPlantService {
  /**
   * @description Event when a single order is being sent.
   */
  public $exchangeOrderNotification: Subject<rti.ExchangeOrderNotification> =
    new Subject();

  /**
   * @description Event when orders are being requested.
   */
  public $requestShowOrders: Subject<any> = new Subject();

  /**
   * @description Event when all requested orders have been fetched.
   * @see response_show_orders.proto
   */
  public $responseShowOrders: Subject<rti.ResponseShowOrders> = new Subject();

  /**
   * @description Event when an order has been cancelled.
   * @see response_cancel_order.proto
   */
  public $responseCancelOrder: Subject<rti.ResponseCancelOrder> = new Subject();

  /**
   * @description Event that happens after exit position request.
   */
  public $responseExitPosition: Subject<rti.ResponseExitPosition> =
    new Subject();

  /**
   * @description Event when rithmic order notification is received.
   */
  public $rithmicOrderNotification: Subject<rti.RithmicOrderNotification> =
    new Subject();

  /**
   * @description List of collected rithmic order notifications with completion reason field.
   */
  public $rithmicOrderNotificationsWithCompletionReason: BehaviorSubject<
    rti.RithmicOrderNotification[]
  > = new BehaviorSubject([]);

  /**
   * @description Event when a trade route has been received.
   */
  public $responseTradeRoutes: Subject<rti.ResponseTradeRoutes> = new Subject();

  /**
   * @description List of collected trade routes.
   */
  public tradeRoutes: rti.ResponseTradeRoutes[] = [];

  /**
   * @see this.subscribeFromTradeRoutes
   * @see this.unsubscribeFromTradeRoutes
   */
  private _tradeRoutesSubscribers: string[] = [];

  /**
   * @description Event when all order history messages for one particular request have been received.
   * @see response_show_order_history.proto
   * @see {@link https://tradrr.atlassian.net/wiki/spaces/TRADRR/pages/60489729/R+Protocol+-+Order+Plant+-+Requests+Responses+and+Relationships|R Protocol - Order Plant - Requests, Responses and Relationships}
   */
  public $responseShowOrderHistory: Subject<any> = new Subject();

  /**
   * @description Event happening when an new bracket order request was sent.
   * @see response_new_order.proto
   */
  public $responseBracketOrder: Subject<rti.ResponseBracketOrder> =
    new Subject();

  /**
   * @description Event happening when an modify order request was sent.
   * @see response_modify_order.proto
   */
  public $responseModifyOrder: Subject<rti.ResponseModifyOrder> = new Subject();

  constructor() {
    this.$rithmicOrderNotification.subscribe(
      (rithmicOrderNotification: rti.RithmicOrderNotification): void => {
        if (this.isZeroQuniatityOrderError(rithmicOrderNotification.quantity)) {
          return;
        }

        this._updateRithmicOrderNotificationsWithCompletionReason(
          rithmicOrderNotification,
        );
      },
    );
  }

  private _updateRithmicOrderNotificationsWithCompletionReason(
    rithmicOrderNotification: rti.RithmicOrderNotification,
  ): void {
    const rithmicOrderNotificationsWithCompletionReason: rti.RithmicOrderNotification[] =
      this.$rithmicOrderNotificationsWithCompletionReason.getValue();

    if (
      rithmicOrderNotificationsWithCompletionReason.includes(
        rithmicOrderNotification,
      ) ||
      !rithmicOrderNotification.completionReason
    ) {
      return;
    }

    this.$rithmicOrderNotificationsWithCompletionReason.next([
      ...this.$rithmicOrderNotificationsWithCompletionReason.getValue(),
      rithmicOrderNotification,
    ]);
  }

  public requestBracketOrder(
    order: IOrder,
    orderPlantWebSocket: RProtocolConnectionWebSocketService,
    isTradingGloballyEnabled: boolean,
  ): Observable<rti.ResponseBracketOrder> {
    return new Observable((subscriber: Subscriber<any>): void => {
      const requestId: string = `RequestBracketOrderID: ${Date.now()}_${Math.random()}`;

      this.$responseBracketOrder
        .pipe(
          filter((response: rti.ResponseBracketOrder): boolean =>
            response.userMsg.includes(requestId),
          ),
          take(1),
        )
        .subscribe((response: rti.ResponseBracketOrder): void => {
          const { rpCode, rqHandlerRpCode } = response;
          let errorMessage: string = 'R-Protocol error(s): ';

          if (rqHandlerRpCode[0] === '0' || rpCode[0] === '0') {
            subscriber.next(response);
          } else {
            subscriber.error(
              new Error(errorMessage + response.rpCode.join('. ')),
            );
          }

          subscriber.complete();
        });

      orderPlantWebSocket.requestBracketOrder(
        order,
        isTradingGloballyEnabled,
        requestId,
      );
    });
  }

  public requestModifyOrder(
    order: IOrder,
    orderPlantWebSocket: RProtocolConnectionWebSocketService,
    isTradingGloballyEnabled: boolean,
  ): Observable<rti.RequestModifyOrder> {
    return new Observable((subscriber: Subscriber<any>): void => {
      const requestId: string = `RequestModifyOrderID: ${Date.now()}_${Math.random()}`;

      this.$responseModifyOrder
        .pipe(
          filter((response: rti.ResponseModifyOrder): boolean =>
            response.userMsg.includes(requestId),
          ),
          take(1),
        )
        .subscribe((response: rti.ResponseModifyOrder): void => {
          const { rpCode, rqHandlerRpCode } = response;
          let errorMessage: string = 'R-Protocol error(s): ';

          if (rqHandlerRpCode[0] === '0' || rpCode[0] === '0') {
            subscriber.next(response);
          } else {
            subscriber.error(
              new Error(errorMessage + response.rpCode.join('. ')),
            );
          }

          subscriber.complete();
        });

      orderPlantWebSocket.requestModifyOrder(
        order,
        isTradingGloballyEnabled,
        requestId,
      );
    });
  }

  public requestCancelOrder(
    order: IOrder,
    orderPlantWebSocket: RProtocolConnectionWebSocketService,
    isTradingGloballyEnabled: boolean,
  ): Observable<rti.RequestCancelOrder> {
    console.log('requestCancelOrder');
    return new Observable((subscriber: Subscriber<any>): void => {
      const requestId: string = `RequestCancelOrderID: ${Date.now()}_${Math.random()}`;

      console.log('requestCancelOrder 2');

      this.$responseCancelOrder
        .pipe(
          filter((response: rti.ResponseCancelOrder): boolean =>
            response.userMsg.includes(requestId),
          ),
          take(1),
        )
        .subscribe((response: rti.ResponseCancelOrder): void => {
          console.log('requestCancelOrder 3');
          const { rpCode, rqHandlerRpCode } = response;
          let errorMessage: string = 'R-Protocol error(s): ';

          if (rqHandlerRpCode[0] === '0' || rpCode[0] === '0') {
            console.log('requestCancelOrder if');
            subscriber.next(response);
          } else {
            console.log('requestCancelOrder else');
            subscriber.error(
              new Error(errorMessage + response.rpCode.join('. ')),
            );
          }

          subscriber.complete();
        });
      orderPlantWebSocket.requestCancelOrder(
        order,
        isTradingGloballyEnabled,
        requestId,
      );
    });
  }

  public requestExitPosition(
    instrument: IInstrument,
    account: OrderAccount,
    orderPlantWebSocket: RProtocolConnectionWebSocketService,
  ): Observable<rti.ResponseExitPosition> {
    return new Observable((subscriber: Subscriber<any>): void => {
      const requestId: string = `RequestExitPositionID: ${Date.now()}_${Math.random()}`;

      this.$responseExitPosition
        .pipe(
          filter((response: rti.ResponseExitPosition): boolean =>
            response.userMsg.includes(requestId),
          ),
          take(1),
        )
        .subscribe((response: rti.ResponseExitPosition): void => {
          const { rpCode, rqHandlerRpCode } = response;
          let errorMessage: string = 'R-Protocol error(s): ';

          if (rqHandlerRpCode[0] === '0' || rpCode[0] === '0') {
            subscriber.next(response);
          } else {
            subscriber.error(
              new Error(errorMessage + response.rpCode.join('. ')),
            );
          }

          subscriber.complete();
        });

      orderPlantWebSocket.requestExitPosition(instrument, account, requestId);
    });
  }

  public subscribeTradeRoutes(
    orderPlantWebSocket: RProtocolConnectionWebSocketService,
    subscriberId: string,
  ): void {
    if (this._tradeRoutesSubscribers.includes(subscriberId)) {
      return;
    } else {
      this._tradeRoutesSubscribers.push(subscriberId);
    }

    if (this._tradeRoutesSubscribers.length > 1) {
      return;
    }

    this.requestTradeRoutes(orderPlantWebSocket, true)
      .pipe(takeWhile((): boolean => this._tradeRoutesSubscribers.length !== 0))
      .subscribe((tradeRoute: rti.ResponseTradeRoutes): void => {
        const collectedTradeRoutes: rti.ResponseTradeRoutes[] =
          this.tradeRoutes;

        delete tradeRoute.userMsg;

        if (collectedTradeRoutes.includes(tradeRoute)) {
          return;
        }

        this.tradeRoutes = [...collectedTradeRoutes, tradeRoute];
      });
  }

  public unsubscribeFromTradeRoutes(unsubscriberId: string): void {
    this._tradeRoutesSubscribers = this._tradeRoutesSubscribers.filter(
      (subscriberId: string) => subscriberId === unsubscriberId,
    );
  }

  public requestTradeRoutes(
    orderPlantWebSocket: RProtocolConnectionWebSocketService,
    subscribeForUpdates?: boolean,
  ): Observable<rti.ResponseTradeRoutes> {
    if (subscribeForUpdates) {
      return new Observable((subscriber: Subscriber<any>): void => {
        this.$responseTradeRoutes
          .pipe(takeWhile(() => this._tradeRoutesSubscribers.length !== 0))
          .subscribe((response: rti.ResponseTradeRoutes): void => {
            const { rpCode, rqHandlerRpCode } = response;

            if (rqHandlerRpCode[0] === '0' && rpCode.length === 0) {
              subscriber.next(response);
            }
          });

        orderPlantWebSocket.requestTradeRoutes(true);
      });
    }

    return new Observable((subscriber: Subscriber<any>): void => {
      const requestId: string = `RequestTradeRoutesID: ${Date.now()}_${Math.random()}`;

      this.$responseTradeRoutes
        .pipe(
          filter((response: rti.ResponseTradeRoutes): boolean =>
            response.userMsg.includes(requestId),
          ),
        )
        .subscribe((response: rti.ResponseTradeRoutes): void => {
          const { rpCode, rqHandlerRpCode } = response;
          let errorMessage: string = 'R-Protocol error(s): ';

          if (rqHandlerRpCode[0] === '0') {
            subscriber.next(response);
          } else if (rpCode[0] === '0') {
            subscriber.complete();
          } else {
            subscriber.error(
              new Error(errorMessage + response.rpCode.join('. ')),
            );
          }
        });

      orderPlantWebSocket.requestTradeRoutes(false, requestId);
    });
  }

  /**
   * @param exchange Name of the marketplace, for example: 'CME', 'CBOT', 'NYMEX'.
   * @returns For example: 'simulator'.
   */
  public getTradeRouteByExchange(exchange: string): string {
    return this.tradeRoutes.find(
      (tradeRoute: rti.ResponseTradeRoutes): boolean =>
        tradeRoute.exchange === exchange,
    ).tradeRoute;
  }

  /**
   * @description Workaround. We have found out that when we try to
   * create a Stop Market order in incorrect side the order gets rejected for our original quantity
   * but additionally also seems to get rejected for an order of 0 quantity as if we are
   * creating two orders by sending two requests instead of one.
   * We want to filter out the notification with the value of 0 for quantity.
   * @todo Remove the workaround if we find a better solution to appraoch this issue.
   */
  public isZeroQuniatityOrderError(quantity: number): boolean {
    return quantity === 0;
  }
}
