<loader *ngIf="loading"></loader>

<window-header
  marginClass=""
  (close)="onWindowClose()"
  className="widget"
  [window]="this"
>
  <instrument-select
    class="mr-1"
    [(instrument)]="instrument"
    [account]="select.account"
  >
  </instrument-select>
  <account-select
    [labelTransformer]="transformAccountLabel.bind(this)"
    #select
    [preferredAccounts]="preferredAccounts"
    [(account)]="account"
  ></account-select>
  <div *ngIf="!connections.length">
    <div (click)="openAccounts()" class="openconnection">No Connection</div>
  </div>
  <div class="vertical-line"></div>
  <div class="d-flex align-items-center instrument-description">
    {{ instrument?.description | nullCoalescing: ''
    }}<ng-container
      *ngIf="instrument?.description != null && instrument?.description != ''"
      >,</ng-container
    >
    {{ this.chart?.timeFrame | timeframeTransform }}
  </div>
  <div class="rithmic-branding mr-auto">
    <img
      height="16"
      src="./assets/img/brokers/rithmic-extended.png"
      alt="Rithmic"
    />
  </div>
  <div class="ml-auto trading-instruments" *ngIf="enableOrderForm">
    <i
      *ngFor="let item of directions"
      (click)="currentDirection = item"
      [class.active]="currentDirection === item"
      class="icon-{{ item }} direction window-icon"
    ></i>
    <i
      (click)="showChartForm = !showChartForm; setNeedUpdate()"
      [class.active]="showChartForm"
      [title]="showChartForm ? 'Hide' : 'Show' + ' order area'"
      class="icon-widget-orders"
    ></i>
    <i
      (click)="toggleTrading()"
      [class.icon-lock]="!isTradingEnabledGlobally || !isTradingEnabled"
      [class.icon-unlock]="isTradingEnabledGlobally && isTradingEnabled"
      [title]="isTradingEnabledGlobally && isTradingEnabled ? 'Lock' : 'Unlock'"
      class="lock"
    ></i>
  </div>
</window-header>
<div class="component-container {{ currentDirection }}">
  <div
    [class.hide-oclc]="!showOHLV"
    [class.hide-changes]="!showChanges"
    class="chart-container"
  >
    <app-toolbar
      #toolbar
      *ngIf="window"
      [intervalOptions]="intervalOptions"
      [periodOptions]="periodOptions"
      (loadedCustomeVolumeProfile)="loadCustomeVolumeTemplate($event)"
      [(enableOrderForm)]="enableOrderForm"
      (enableOrderFormChange)="checkIfTradingEnabled()"
      [class.location-up]="!showChanges && !showOHLV"
      [layout]="layout"
      [chart]="chart"
      [window]="window"
      [link]="chartLink"
    ></app-toolbar>
    <div class="chart" #chartContainer></div>
  </div>

  <side-form
    #chartForm
    [class.d-none]="!showChartForm || !enableOrderForm"
    [accountId]="accountId"
    [position]="position"
    [ocoStep]="ocoStep"
    [orders]="orders"
    (actions)="handleFormAction($event)"
    [instrument]="instrument"
  >
    <div class="form-header" headerContent>
      <info-component [formatter]="formatter" headerContent></info-component>
    </div>
  </side-form>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul class="chart-menu-dropdown" nz-menu *ngIf="!activeIndicator">
    <li (click)="toggleToolbarVisibility()" nz-menu-item>
      {{ isToolbarVisible ? 'Hide Toolbar' : 'Show Toolbar' }}
    </li>
    <li (click)="showOHLV = !showOHLV; updateInSettings()" nz-menu-item>
      {{ showOHLV ? 'Hide' : 'Show' }} OHLV
    </li>
    <li (click)="showChanges = !showChanges; updateInSettings()" nz-menu-item>
      {{ showChanges ? 'Hide' : 'Show' }}
      Changes
    </li>
    <li nz-menu-divider></li>
    <li (click)="saveTemplate()" [nzDisabled]="!loadedTemplate" nz-menu-item>
      Save
    </li>
    <li (click)="openNewTemplatePopup()" nz-menu-item>Save as ...</li>
    <li (click)="saveAsDefault()" [nzDisabled]="!chartTemplates" nz-menu-item>
      Save as default
    </li>
    <!--  <li nz-menu-divider></li>
      <li nz-menu-item>Reset PB</li>
     -->
    <li nz-menu-divider></li>
    <li nz-menu-item (click)="openSettingsDialog()">Settings</li>
  </ul>

  <ul class="chart-menu-dropdown" nz-menu *ngIf="activeIndicator">
    <li (click)="openVolumeSettingsDialog()" nz-menu-item>Settings</li>
    <li nz-menu-divider></li>
    <li class="custom-volume-profile-templates" nz-submenu nzTitle="Templates">
      <ul class="custom-volume-profile-subitems">
        <li
          *ngFor="let template of toolbar.items"
          class="custom-drawing-menu-item"
          (click)="selectCustomeVolumeTemplate(template)"
          nz-menu-item
        >
          {{ template.name }}
          <span *ngIf="!template.isDefault" class="actions"
            ><i (click)="editCustomProfile(template)" class="icon-edit"></i>
            <i
              *ngIf="!template.isDefault"
              (click)="deleteVolumeProfile(template)"
              class="icon-close-window"
            ></i
          ></span>
        </li>
      </ul>
    </li>
    <li
      (click)="saveCustomVolume()"
      [nzDisabled]="activeIndicator.templateId == null"
      nz-menu-item
    >
      Save
    </li>
    <li (click)="saveAsCustomVolume()" nz-menu-item>Save as</li>
    <li nz-menu-divider></li>
    <li (click)="removeCustomeVolumeProfile()" nz-menu-item>Delete</li>
  </ul>
</nz-dropdown-menu>
