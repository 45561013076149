import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as Sentry from '@sentry/angular';
import { AuthService } from 'auth';
import { NzMessageService } from 'ng-zorro-antd';
import { SettingsService } from 'settings';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFeedbackComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter();

  form: FormGroup;
  isFormSubmitted: boolean = false;

  constructor(
    private _authService: AuthService,
    private _settingsService: SettingsService,
    private _messageService: NzMessageService,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      message: new FormControl('', [Validators.required]),
    });
  }

  submit(): void {
    const eventId: string = new Date().getTime() + '';
    const eventName: string = `User Feedback (ID: ${eventId})`;
    const email: string = this._authService.userInfo.name;
    const message: string = `${eventName}:\n\n${this.form.get('message').value}`;
    const sentryScope: Sentry.Scope = Sentry.getCurrentScope();
    let sentryEventId: string;

    this.isFormSubmitted = true;

    if (this.form.get('message').hasError('required')) {
      return;
    }

    sentryScope.setFingerprint([eventName]);
    sentryScope.addAttachment({
      filename: `settings_${eventId}.json`,
      data: JSON.stringify(this._settingsService.settings.value),
    });
    sentryEventId = Sentry.captureMessage(eventName, {
      tags: {
        email,
      },
    });
    Sentry.captureUserFeedback({
      event_id: sentryEventId,
      name: email,
      email,
      comments: message,
    });
    sentryScope.clearAttachments();
    sentryScope.clear();

    this.close.emit();
    this._messageService.success('Your feedback has been sent');
  }
}
