import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AccountsManager } from 'accounts-manager';
import {
  Id,
  RProtocolConnectionWebSocketService,
  WSEventType,
} from 'communication';
import { RProtocolMessageTemplateNameEnum } from 'projects/communication/src/services/rprotocol/rprotocol-message-template-name-enum';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { IInstrument, OnUpdateFn, SettleData, UnsubscribeFn } from 'trading';

import { FeedRelayService } from './feed-relay.service';
import { RealFeed, WSMessageTypes } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealSettleDataFeed extends RealFeed<SettleData, IInstrument> {
  type = RealtimeType.Settle;
  _lastValue: {
    [connectionId: string]: {
      [instrumentId: string]: SettleData;
    };
  } = {};

  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;
  private response$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  get useFeedRelay(): boolean {
    return true;
  }

  constructor(
    protected _injector: Injector,
    @Inject(WebSocketRegistryService)
    protected _webSocketRegistryService: WebSocketRegistryService,
    @Inject(AccountsManager) protected _accountsManager: AccountsManager,
    @Inject(FeedRelayService) protected _feedRelay: FeedRelayService,
  ) {
    super(_injector, _webSocketRegistryService, _accountsManager, _feedRelay);

    this._webSocketService.on(WSEventType.Message, (event: any) => {
      if (
        event.type === RProtocolMessageTemplateNameEnum.ResponseMarketDataUpdate
      ) {
        this.response$.next(event.message);
      }
    });
  }

  on(fn: OnUpdateFn<SettleData>): UnsubscribeFn {
    for (const connectionId in this._lastValue) {
      for (const key in this._lastValue[connectionId]) {
        const value = this._lastValue[connectionId][key];
        fn(value, connectionId);
      }
    }
    return super.on(fn);
  }

  protected _handleUpdate(data, connectionId) {
    const result = super._handleUpdate(data, connectionId);
    if (result) {
      const settleData = this._getResult(data);
      if (!this._lastValue[connectionId]) {
        this._lastValue[connectionId] = {};
      }
      this._lastValue[connectionId][settleData.instrument.id] = settleData;
      return true;
    }
  }

  subscribe(instrument: IInstrument, connectionId: Id): string {
    if (this.isMainWindow) {
      const webSocket: RProtocolConnectionWebSocketService =
        this._webSocketService.getWebSocket(
          connectionId,
        ) as RProtocolConnectionWebSocketService;
      return webSocket.subscribeToSettlementPrice(instrument);
    }
    super.subscribe(instrument, connectionId);
    return '';
  }

  unsubscribe(instrument: IInstrument, connectionId: Id): string {
    if (this.isMainWindow) {
      const webSocket: RProtocolConnectionWebSocketService =
        this._webSocketService.getWebSocket(
          connectionId,
        ) as RProtocolConnectionWebSocketService;
      return webSocket.unsubscribeToSettlementPrice(instrument);
    }
    super.subscribe(instrument, connectionId);
    return '';
  }

  requestSettlementPrice(instrument: IInstrument, connectionId: Id): void {
    if (this.isMainWindow) {
      const requestUid: string = this.unsubscribe(instrument, connectionId);

      this.response$.subscribe((message: any) => {
        if (message?.requestUid === requestUid) {
          this.subscribe(instrument, connectionId);
        }
      });
      return;
    }

    if (this.isChildWindow && this.useFeedRelay) {
      this.log(
        this.constructor.name,
        'requestSettlementPrice(): sending to main window',
        { instrument, connectionId },
      );
      this._feedRelay.sendToMainWindow(this.type, {
        methodName: 'requestSettlementPrice',
        methodArgs: [instrument, connectionId],
        origin: this.constructor.name,
      });
      return;
    }

    console.warn(
      this.constructor.name,
      'requestSettlementPrice(): not implemented',
    );
  }
}
