import { Component, OnInit } from '@angular/core';

import { DebugFlagService } from '../debug-flag.service';

@Component({
  selector: 'performance-metrics-toolbar',
  templateUrl: './performance-metrics-toolbar.component.html',
  styleUrls: ['./performance-metrics-toolbar.component.scss'],
})
export class PerformanceMetricsToolbarComponent implements OnInit {
  isDebugMode: boolean = false;

  constructor(private debugFlagService: DebugFlagService) {
    this.debugFlagService.debugMode$.subscribe((mode: boolean) => {
      this.isDebugMode = mode;
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {}
}
