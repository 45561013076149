import { Inject, Injectable, Injector } from '@angular/core';

import { AccountsManager } from 'accounts-manager';
import { WEB_SOCKET_TYPE } from 'communication';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { AccountInfo, IOrder } from 'trading';

import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealAccountPnlFeed extends RealFeed<IOrder> {
  type = RealtimeType.Position;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RAPI;
  }

  get useFeedRelay(): boolean {
    return true;
  }

  protected _filter(item: IOrder): boolean {
    // Filters out position-level PnL updates, keeps account-level PnL updates
    if (item?.instrument) return false;
    return super._filter(item);
  }

  protected _map(data: any): AccountInfo {
    if (Array.isArray(data)) {
      throw new Error('TODO implement this');
    }
    return this._mapResponseItem(data);
  }

  protected _mapResponseItem(item: any): AccountInfo {
    return {
      id: item.account.id,
      connectionId: item.connectionId,
      account: item.account,
      name: item.account?.name,
      fcmId: item.account?.fcmId,
      ibId: item.account?.ibId,
      accountBalance: item.accountBalance,
      closedPnl: item.realisedPL,
      openPnl: item.unrealisedPL,
      position: item.volume,
      buyQty: item.buyVolume,
      sellQty: item.sellVolume,
      // TODO these values are not sent in the continuous account PnL updates, check whether it's a (backend?) bug or a R API limitation
      cashOnHand: null,
      currency: null,
      lossLimit: null,
      autoLiquidateThreshold: null,
    } as AccountInfo;
  }
}
