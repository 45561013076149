import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  AlertType,
  IConnectionWebSocket,
  Id,
  WSEventType,
} from 'communication';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from 'notification';
import {
  RITHMIC_INFRA_TYPE,
  WEB_SOCKET_TYPE,
} from 'projects/communication/src/services/types';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { Sound, SoundService } from 'sound';
import {
  AccountRepository,
  ConnectionContainer,
  ConnectionsRepository,
  IAccount,
  IConnection,
} from 'trading';

// Todo: Make normal import
// The problem now - circular dependency
import { accountsListeners } from '../../real-trading/src/connection/accounts-listener';
import { AppConnectivityObserver } from './app-connectivity-observer';
import { Connection } from './connection';

@Injectable()
export class AccountsManager
  extends AppConnectivityObserver
  implements ConnectionContainer
{
  get connections(): Connection[] {
    return this.connectionsChange.value;
  }

  private get _connections(): Connection[] {
    return this.connectionsChange.value;
  }

  private set _connections(value: Connection[]) {
    this.connectionsChange.next(value);
  }

  // tslint:disable-next-line:variable-name
  private __accounts: IAccount[] = [];

  private get _accounts(): IAccount[] {
    return this.__accounts;
  }

  private set _accounts(value: IAccount[]) {
    this.__accounts = value.filter(
      (a, index, arr) => arr.findIndex((i) => i.id === a.id) === index,
    );
  }

  private _soundService: SoundService;

  private _wsIsOpened = {};
  private _wsHasError = {};
  private _accountsConnection = new Map();

  connectionsChange = new BehaviorSubject<Connection[]>([]);

  // private _updateConnectionsMap: { [key: string]: BehaviorSubject<IConnection> } = {};
  // private _requests: { [key: string]: Observable<IConnection> } = {};

  private _pendingNotify = false;

  constructor(
    protected _injector: Injector,
    private _connectionsRepository: ConnectionsRepository,
    private _accountRepository: AccountRepository,
    private _webSocketRegistryService: WebSocketRegistryService,
    private _notificationService: NotificationService,
    private _cookieService: CookieService,
  ) {
    super();
  }

  private _getSoundService(): any {
    if (!this._soundService) {
      this._soundService = this._injector.get(SoundService);
    }

    return this._soundService;
  }

  getConnectionByAccountId(accountId: Id): IConnection {
    if (!accountId) return null;

    return this._accountsConnection.get(accountId);
  }

  getAccountById(id: Id): IAccount {
    return this._accounts.find(
      (account: IAccount): boolean => account.id === id,
    );
  }

  getAccountsByConnection(connId: Id) {
    return this._accounts.filter((item) => item.connectionId === connId);
  }

  getConnection(connectionId: Id): IConnection {
    if (!connectionId) return null;

    for (const connection of this._accountsConnection.values()) {
      if (connection.id === connectionId) return connection;
    }

    return this._connections.find((item) => item.id === connectionId);
  }

  getaActiveConnectionAccount(): IAccount {
    const activeConnection = this.getFirstActiveConnection();
    if (!activeConnection) {
      return null;
    }
    return this.getAccountsByConnection(activeConnection.id).shift();
  }

  getFirstActiveConnection(): IConnection {
    return this._connections.find((item) => item.connected);
  }

  getDefaultConnection(): IConnection {
    return this._connections.find((item) => item.isDefault);
  }

  async init(): Promise<IConnection[]> {
    await this._fetchConnections();
    for (const conn of this._connections
      .filter((i) => i.canConnectOnStartUp)
      .sort((i) => (i.isDefault ? -1 : 1)))
      this.connect(conn).subscribe(
        () => {
          /*console.log('Successfully connected', conn)*/
        },
        (err) => console.error('Connected error', conn, err),
      );

    return this._connections;
  }

  protected async onOffline(event: Event) {
    await super.onOffline(event);
    if (!this._connections?.length) {
      return;
    }
    this._connections
      .filter((connection: Connection) => connection.connected)
      .forEach((connection: Connection) => {
        console.log('Connection marked as offline: ', connection.name);
        connection.markAsOffline();
      });
  }

  protected async onOnline(event: Event): Promise<void> {
    await super.onOnline(event);
    if (!this._connections?.length) {
      return;
    }
    this._connections
      .filter((connection: Connection) => connection.loading)
      .forEach((connection: Connection) => {
        console.log('Connection marked as online: ', connection.name);
        connection.markAsOnline();
      });
  }

  private _fetchAccounts(connection: Connection) {
    this._getAccountsByConnections(connection)
      .then((accounts) => {
        this._accounts = this._accounts.concat(accounts);

        for (const account of accounts) {
          this._accountsConnection.set(account.id, connection);
        }

        accountsListeners.notifyAccountsConnected(accounts, this._accounts);
      })
      .catch((e) => {
        console.error('error', e);
        this.disconnect(connection).subscribe();
      });
  }

  private async _fetchConnections(): Promise<void> {
    return this._connectionsRepository
      .getItems()
      .toPromise()
      .then((res) => {
        this._connections = res.data.map((item) => {
          // TODO: Move to constructor|from json if possible
          item.connected = false;
          if (item.connected && !item.connectOnStartUp) {
            delete item.connectionData;
          }

          return this.getNewConnection(item);
        });
      });
  }

  private async _getAccountsByConnections(
    connection: IConnection,
  ): Promise<IAccount[]> {
    if (!connection) {
      return Promise.resolve([]);
    }

    const params = {
      status: 'Active',
      criteria: '',
      connection,
    };

    return this._accountRepository
      .getItems(params)
      .pipe(
        catchError((e) => {
          console.error('_getAccountsByConnections', e);
          return of({ data: [] } as any);
        }),
      )
      .toPromise()
      .then((i) => i.data);
  }

  private _initialiseWebSocketSubscription(
    connection: Connection,
    webSocketService: IConnectionWebSocket,
  ): void {
    const subscription: Subscription = webSocketService.reconnection$
      .pipe(switchMap(() => this.reconnect(connection)))
      .subscribe(
        (conn: IConnection): void => {
          this._notificationService.showSuccess(
            `Reconnected ${conn?.name ?? ''}`,
          );
        },
        (err): void => {
          this._notificationService.showError(err, 'Error during reconnection');
        },
        (): void => subscription.unsubscribe(),
      );

    const subscriptionLoggedIn: Subscription = webSocketService.loggedIn$
      .pipe(
        switchMap((value: boolean) => {
          connection.isLoggedInWithBroker = value;
          return of(value);
        }),
      )
      .subscribe(
        (conn) => {
          // this._notificationService.showSuccess(`Logged in via R Protocol ${conn?.name ?? ''}`);
        },
        (err) => {
          // this._notificationService.showError(err, 'Error during logging in');
        },
        () => subscriptionLoggedIn.unsubscribe(),
      );

    webSocketService.on(WSEventType.Message, this._wsHandleMessage.bind(this));
    webSocketService.on(WSEventType.Open, this._wsHandleOpen.bind(this));
    webSocketService.on(WSEventType.Error, this._wsHandleError.bind(this));
    webSocketService.on(WSEventType.Close, this._wsHandleClose.bind(this));

    webSocketService.connect();

    webSocketService.send(
      { type: 'Id', value: connection?.connectionData?.apiKey },
      connection?.id,
    );
  }

  private _initialiseWebSockets(connection: Connection): void {
    const rApiWebSocket: IConnectionWebSocket =
      this._webSocketRegistryService.get(connection, WEB_SOCKET_TYPE.RAPI);
    let tickerPlantWebSocket: IConnectionWebSocket;
    let orderPlantWebSocket: IConnectionWebSocket;

    this._initialiseWebSocketSubscription(connection, rApiWebSocket);

    if (!connection.useHybridInfraMode) {
      return;
    }

    tickerPlantWebSocket = this._webSocketRegistryService.get(
      connection,
      WEB_SOCKET_TYPE.RPROTOCOL,
      RITHMIC_INFRA_TYPE.TICKER_PLANT,
    );
    orderPlantWebSocket =
      this._webSocketRegistryService.getRProtocolOrderPlant(connection);

    this._initialiseWebSocketSubscription(connection, tickerPlantWebSocket);
    this._initialiseWebSocketSubscription(connection, orderPlantWebSocket);
  }

  private _closeWS(
    connection: IConnection,
    code: number = 1000,
    reason?: string,
  ) {
    const webSocketService = this._webSocketRegistryService.get(connection);
    let tickerPlantWebSocket: IConnectionWebSocket;
    let orderPlantWebSocket: IConnectionWebSocket;

    webSocketService.destroy(connection, code, reason);

    if (connection.useHybridInfraMode) {
      tickerPlantWebSocket = this._webSocketRegistryService.getRProtocol(
        connection,
        RITHMIC_INFRA_TYPE.ORDER_PLANT,
      );
      orderPlantWebSocket =
        this._webSocketRegistryService.getRProtocolOrderPlant(connection);

      tickerPlantWebSocket.destroy(connection, code, reason);
      orderPlantWebSocket.destroy(connection, code, reason);
    }
  }

  protected _wsHandleMessage(msg: any, connectionId: string): void {
    if (
      (msg.type === 'Connect' &&
        (msg.result.type === AlertType.ConnectionClosed ||
          msg.result.type === AlertType.ConnectionBroken ||
          msg.result.type === AlertType.ForcedLogout)) ||
      (msg.type === 'Error' &&
        msg.result.value.toLowerCase().includes('no connection'))
    ) {
      const connection = this.getConnection(connectionId) as Connection;
      const conWSS = this._webSocketRegistryService.get(connection);
      if (conWSS.isDraining) {
        // Since this is a DrainEvent, erase load-balancer's sticky cookie
        this._cookieService.delete('AWSALB', '/');
        this._cookieService.delete('AWSALBCORS', '/');
        conWSS.destroy(connection, 1000, 'Draining');
      } else {
        this._deactivateConnection(connectionId);
      }
    }
  }

  private _wsHandleOpen(event, connectionId) {
    if (!this._wsIsOpened[connectionId]) {
      this._wsIsOpened[connectionId] = true;
      const conn = this._connections.find((item) => item.id === connectionId);
      this._wsHasError[connectionId] = false;
      this._notificationService.showSuccess(
        `Connection ${conn?.name ?? ''} opened.`,
      );
    }
  }

  private _wsHandleError(event: ErrorEvent, connectionId) {
    delete this._wsIsOpened[connectionId];

    if (this._wsHasError[connectionId] === true) {
      return;
    }

    this._wsHasError[connectionId] = true;
    console.log('ws error', event, connectionId);
    this._notificationService.showError(
      event,
      `Connection lost. Check your internet connection.`,
    );

    // if (connection?.connected) {
    //   this.onUpdated({
    //     ...connection,
    //     error: true,
    //   });
    // }
  }

  private _wsHandleClose(event, connId) {
    delete this._wsIsOpened[connId];
    const { code } = event;
    let { reason } = event;
    const connection = this.getConnection(connId) as Connection;
    if (connection) {
      const conWSS = this._webSocketRegistryService.get(connection);
      if (!reason && conWSS.isDraining) {
        reason = 'Draining';
      }
      const isReconnectionNeeded = conWSS.isDraining;
      if (isReconnectionNeeded) {
        // The ongoing close event was caused by server-side draining, reconnect the client immediately
        conWSS.stopDraining(connId);
        setTimeout(() => {
          const subscription = this.connect(connection).subscribe(
            (conn) => {
              this._notificationService.showSuccess(
                `Connection ${conn?.name ?? ''} restored.`,
                `Reason: ${reason}, code: ${code}`,
              );
            },
            (err) => {
              console.error(err);
            },
            () => subscription.unsubscribe(),
          );
        }, 100);
      }
    }
  }

  private _deactivateConnection(connectionId: string): void {
    if (!connectionId) {
      return;
    }
    const connection = this._connections.find(
      (item) => item.id === connectionId,
    );
    if (!connection) {
      return;
    }

    // connection.connected = false;
    connection
      .disconnect()
      .pipe(tap(() => this._onDisconnected(connection)))
      .subscribe(
        () => console.log('Successfully deactivate'),
        (err) => console.error('Deactivate error ', err),
      );
    // this.updateItem(connection)
    //   .pipe(
    //     tap(() => this._onDisconnected(connection)),
    //     // tap(() => this.onUpdated(connection))
    //   ).subscribe(
    //     () => console.log('Successfully deactivate'),
    //     (err) => console.error('Deactivate error ', err),
    //   );
  }

  remove(connection: Connection): Observable<any> {
    return connection
      .remove()
      .pipe(
        tap(
          () =>
            (this._connections = this._connections.filter(
              (i) => i !== connection,
            )),
        ),
      );
  }

  createConnection(connection: Connection): Observable<IConnection> {
    if (this._connections.some(hasConnection(connection)))
      return throwError("You can 't create duplicated connection");

    return connection
      .create()
      .pipe(
        tap(() => (this._connections = this._connections.concat(connection))),
      );
  }

  connect(connection: Connection): Observable<IConnection> {
    const defaultConnection = this.getDefaultConnection();
    return connection
      .connect(
        defaultConnection == null || defaultConnection.id === connection.id,
      )
      .pipe(
        tap((conn) => {
          if (conn.error) {
            this._notificationService.showError(conn.err, 'Connection error');
          }

          if (conn.connected) {
            this._initialiseWebSockets(conn);
            this._fetchAccounts(conn);
            this._getSoundService().play(Sound.CONNECTED);
          }
        }),
        tap(() =>
          accountsListeners.notifyConnectionsConnected(
            [connection],
            this._connections.filter((i) => i.connected),
          ),
        ),
      );
  }

  reconnect(connection: Connection): Observable<IConnection> {
    return this.disconnect(connection).pipe(
      switchMap(() => this.connect(connection)),
    );
  }

  private _onDisconnected(connection: IConnection) {
    const disconnectedAccounts = this._accounts.filter(
      (account) => account.connectionId === connection.id,
    );
    this._accounts = this._accounts.filter(
      (account) => account.connectionId !== connection.id,
    );
    for (const account of disconnectedAccounts) {
      this._accountsConnection.delete(account.id);
    }
    accountsListeners.notifyConnectionsDisconnected(
      [connection],
      this._connections.filter((i) => i.connected),
    );
    accountsListeners.notifyAccountsDisconnected(
      disconnectedAccounts,
      this._accounts,
    );
    this._closeWS(connection);
    this._getSoundService().play(Sound.CONNECTION_LOST);
  }

  disconnectById(connectionId: string) {
    if (!connectionId) return;

    this.disconnect(
      this._connections.find((i) => i.id === connectionId),
    ).subscribe(
      (i) => console.log('Successfully disconnect'),
      (err) => console.error('Error disconnect ', err),
    );
  }

  disconnect(connection: Connection): Observable<void> {
    if (!connection || !connection.connected) return of();
    if (connection.useHybridInfraMode) {
      const orderPlantebSocket: IConnectionWebSocket =
        this._webSocketRegistryService.getRProtocol(
          connection,
          RITHMIC_INFRA_TYPE.ORDER_PLANT,
        );
      const tickerPlantWebSocket: IConnectionWebSocket =
        this._webSocketRegistryService.getRProtocol(
          connection,
          RITHMIC_INFRA_TYPE.TICKER_PLANT,
        );
      orderPlantebSocket.initiateClosing();
      tickerPlantWebSocket.initiateClosing();
    }
    return connection.disconnect().pipe(
      tap(() => {
        this._onDisconnected(connection);
        if (connection.error)
          this._notificationService.showError(
            connection.err,
            `Connection ${connection?.name ?? ''} is closed`,
          );
        else
          this._notificationService.showSuccess(
            `Connection ${connection?.name ?? ''} is closed`,
          );
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.message.toLowerCase().includes('no connection'))
          this._onDisconnected(connection);

        if (err.status === 401) {
          // this.onUpdated(updatedConnection);
          this._onDisconnected(connection);
          return of(null);
        } else return throwError(err);
      }),
    );
  }

  makeDefault(item: Connection): Observable<any> | null {
    if (item.isDefault) return throwError('Connection is already default');

    // const _connection = { ...item, isDefault: true };
    const defaultConnections = this._connections.filter((i) => i.isDefault);
    if (
      defaultConnections == null ||
      defaultConnections.some((conn) => item.id == conn.id)
    ) {
      return of(item);
    }

    return forkJoin(
      defaultConnections
        .map((i) => i.makeDefault(false))
        .concat(item.makeDefault()),
    ).pipe(tap(() => this._onDefaultChanged(item)));

    // const needUpdate = defaultConnections.map(i => ({ ...i, isDefault: false })).concat(_connection);
  }

  private _onDefaultChanged(item) {
    accountsListeners.notifyDefaultChanged(this._connections, item);
    this._triggerConnectionsChange();
  }

  deleteConnection(connection: Connection): Observable<any> {
    const { id } = connection;

    return (connection.connected ? this.disconnect(connection) : of(null)).pipe(
      mergeMap(() => this._connectionsRepository.deleteItem(id)),
      catchError((error) => {
        if (error.status === 401)
          return this._connectionsRepository.deleteItem(id);

        return throwError(error);
      }),
      tap(() => {
        this._connections = this._connections.filter((i) => i.id !== id);
        connection.destroy();
      }),
    );
  }

  getNewConnection(item = {}): Connection {
    return new Connection(
      this._connectionsRepository,
      this._triggerConnectionsChange,
    ).fromJson(item);
  }

  private _triggerConnectionsChange = () => {
    if (this._pendingNotify) return;

    requestAnimationFrame(() => {
      this.connectionsChange.next(this.connections);
      this._pendingNotify = false;
    });
  };
}

@Injectable()
export class RootAccountsManager extends AccountsManager {}

function hasConnection(connection: IConnection) {
  return (conn: IConnection) =>
    conn.username === connection.username &&
    conn.server === connection.server &&
    conn.gateway === connection.gateway;
}
