import { IInstrument } from './instrument';

export enum QuoteSide {
  Ask = 'Ask',
  Bid = 'Bid',
}

export enum UpdateType {
  Begin = 'Begin',
  Middle = 'Middle',
  End = 'End',
  Solo = 'Solo',
  Undefined = 'Undefined', //best bid/ask
  Snapshot = 'Snapshot', // R Protocol specific
}

export interface IQuote {
  timestamp: number;
  side: QuoteSide;
  instrument: IInstrument;
  price: number;
  volume: number;
  orderCount: number;
  updateType: UpdateType;
  level?: DataLevel;
}

export enum DataLevel {
  Level1 = 'Level1',
  Level2 = 'Level2',
}
