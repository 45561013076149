import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ILoadingHandler } from 'base-components';
import { Id } from 'communication';
import { AccountListener, IAccountListener } from 'real-trading';
import { IAccount } from 'trading';

export interface AccountSelectComponent extends IAccountListener {}

@Component({
  selector: 'account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['account-select.component.scss'],
})
@AccountListener()
export class AccountSelectComponent {
  @Input() placeholder: string = 'Select account';
  @Input() className: string = '';
  @Input() nzDropdownClassName: string = '';
  @Input() loadingHandler: ILoadingHandler;
  @Output() accountChange: EventEmitter<IAccount> = new EventEmitter();

  @Input() selectFirstAsDefault: boolean;
  private _account: IAccount;

  protected _preferredAccounts?: Map<Id, Id>;
  @Input()
  public set preferredAccounts(accounts: Map<Id, Id>) {
    this._preferredAccounts = accounts;
  }

  public get preferredAccounts(): Map<Id, Id> {
    return this._preferredAccounts;
  }

  _connect: boolean = true;

  public get account(): IAccount {
    if (this._account === undefined || this._account === null) {
      this.placeholder = 'No Connection';
      this._connect = false;
    } else {
      this._connect = true;
    }

    return this._account;
  }

  onAccountChanged(): void {
    if (!this.accounts.some((item) => item.id === this._account?.id)) {
      this.account = null; //this.accounts.length ? this.accounts[0] : null;
    }
  }

  @Input()
  public set account(value: IAccount) {
    if (this._account?.id === value?.id) return;

    this.accountChange.emit(value);
    this._account = value;
  }

  get items(): IAccount[] {
    return this.accounts ?? [];
  }

  @Input() labelTransformer = (label) => label;
  @Input() isOpened = false;

  compareAccounts(a: IAccount, b: IAccount): boolean {
    return a?.id === b?.id;
  }
}
