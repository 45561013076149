import { Component } from '@angular/core';

import { MixinHelper } from 'base-components';
import { RepositoryActionData } from 'communication';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NotifierService } from 'notifier';
import { TemplatesService } from 'templates';
import { CreateModalComponent } from 'ui';
import { saveData } from 'window-manager';

import { Components } from 'src/app/components/components.model';
import { IBaseTemplate } from './models';

const { mixinDecorator } = MixinHelper;

export interface IPresets<T> {
  loadedPresets: IBaseTemplate<T>;
  _modalService: NzModalService;
  _templatesService: TemplatesService;
  _notifier: NotifierService;
  loadState(state: T);
  saveState(): T;
  getTabState?: () => saveData;
  loadTemplate(presets: IBaseTemplate<T>): void;
  ngAfterViewInit(): void;
  savePresets(): void;
  openNewPresetPopup(componentType: Components): void;
  saveAsDefault(componentType: Components): void;
}

export interface _Presets<T> extends IPresets<T> {}

@Component({
  template: '',
})
export abstract class _Presets<T> implements IPresets<T> {
  loadedPresets: IBaseTemplate<T>;
  _templates: IBaseTemplate[];
  _modalService: NzModalService;
  _templatesService: TemplatesService;
  _notifier: NotifierService;

  abstract loadState(state: T): void;
  abstract saveState(): T;

  ngAfterViewInit(): void {
    this._templatesService.subscribe(
      (data: RepositoryActionData<IBaseTemplate>): void => {
        this._templates = data.items;

        if (this.loadedPresets) {
          return;
        }

        this.loadedPresets = data.items.find(
          (i) => this.loadedPresets?.id === i?.id,
        );
      },
    );
  }

  loadTemplate(presets: IBaseTemplate<T>): void {
    this.loadedPresets = presets;
    this.loadState(presets.state);
  }

  savePresets(
    presets?: Pick<IBaseTemplate, 'id' | 'name' | 'type' | 'isDefault'>,
  ): void {
    let templateData: IBaseTemplate;

    if (!this.loadedPresets && !presets) {
      return;
    }

    templateData = {
      state: this.saveState(),
      tabState: this.getTabState(),
      name: this.loadedPresets?.name,
      id: this.loadedPresets?.id,
      type: this.loadedPresets?.type,
      isDefault: false,
      ...presets,
    };

    this._templatesService.updateItem(templateData).subscribe(
      (result: IBaseTemplate): void => {
        this.loadedPresets = result;
      },
      (error): void =>
        this._notifier.showError(error, `Failed to save: ${templateData.name}`),
    );
  }

  openNewPresetPopup(componentType: Components): void {
    const modal: NzModalRef = this._modalService.create({
      nzWidth: 440,
      nzTitle: 'Save as',
      nzContent: CreateModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        name: 'Preset name',
      },
    });

    modal.afterClose.subscribe(({ name }): void => {
      if (!name) {
        return;
      }

      this._createPresets({
        name,
        type: componentType,
        isDefault: false,
      });
    });
  }

  private _createPresets(
    preset: Pick<IBaseTemplate, 'type' | 'name' | 'isDefault'>,
  ): void {
    const templateData: Omit<IBaseTemplate, 'id'> = {
      state: this.saveState(),
      tabState: this.getTabState(),
      isDefault: false,
      ...preset,
    };

    this._templatesService.createItem(templateData).subscribe(
      (result: IBaseTemplate): void => {
        this.loadedPresets = result;
      },
      (error): void => {
        this._notifier.showError(
          error,
          `Failed to create preset: ${templateData.name}`,
        );
      },
    );
  }

  saveAsDefault(componentType: Components): void {
    const templateData: Omit<IBaseTemplate, 'id'> = {
      name: this._templatesService.DEFAULT_TEMPLATE_NAME,
      type: componentType,
      isDefault: true,
    };
    let componentTemplates: IBaseTemplate[];

    if (!this._templates) {
      return;
    }

    componentTemplates = this._templates.filter(
      (template: IBaseTemplate): boolean => template.type === componentType,
    );

    if (this._templatesService.hasDefaultTemplate(componentTemplates)) {
      this.savePresets({
        id: this._templatesService.getDefaultTemplate(componentTemplates).id,
        ...templateData,
      });
    } else {
      this._createPresets(templateData);
    }
  }
}

export function LayoutPresets() {
  return mixinDecorator(_Presets, ['ngAfterViewInit']);
}
