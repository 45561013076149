<div class="grid" #tableContainer></div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="columns-items column-main-menu">
    <li *ngIf="showHeaderPanelInContextMenu" nz-menu-item>
      <label
        [(nzChecked)]="contextMenuState.showHeaderPanel"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (nzCheckedChange)="changeShowPanel($event)"
        nz-checkbox
        >Header Panel</label
      >
    </li>
    <li nz-menu-item>
      <label
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (nzCheckedChange)="toggleColumns()"
        nz-checkbox
        [(nzChecked)]="contextMenuState.showColumnHeaders"
        >Column Headers</label
      >
    </li>
    <li nz-menu-divider></li>
    <ng-container *ngFor="let item of customMenuItems">
      <li
        nz-menu-item
        *ngIf="!item.divider; else divider"
        (click)="item.action()"
      >
        {{ item.title }}
      </li>
      <ng-template #divider>
        <li nz-menu-divider></li>
      </ng-template>
    </ng-container>
    <li nz-menu-item (click)="setFitColumnsWidth()">Best Fit Columns</li>
    <li nz-menu-divider></li>

    <li
      *ngIf="templatesEnabled"
      nz-menu-item
      [nzDisabled]="!loadedPresets"
      (click)="save()"
    >
      Save
    </li>
    <li *ngIf="templatesEnabled" nz-menu-item (click)="saveAs()">
      Save as ...
    </li>
    <li
      *ngIf="templatesEnabled"
      nz-menu-item
      [nzDisabled]="disableSaveAsDefault"
      (click)="!disableSaveAsDefault && saveAsDefault.emit()"
    >
      Save as default
    </li>

    <li *ngIf="templatesEnabled" nz-menu-divider></li>
    <!-- Table view section start -->
    <li nz-submenu nzMenuClassName="columns-items" nzTitle="Table View">
      <ul>
        <li *ngFor="let item of getShownColumns()" nz-menu-item>
          <label
            *ngIf="item.canHide; else nameTemplate"
            nz-checkbox
            (click)="$event.stopPropagation()"
            [nzDisabled]="item.disabled"
            (nzCheckedChange)="changeColumnVisibility(item, $event)"
            [nzChecked]="item.visible"
          >
            <ng-template [ngTemplateOutlet]="nameTemplate"></ng-template>
          </label>
          <ng-template #nameTemplate>
            <span>{{ getColumnName(item) }}</span>
          </ng-template>
          <span
            (click)="$event.stopPropagation(); changeAlign(item)"
            class="align"
          >
            <i class="icon-align-{{ item.style.textAlign.toLowerCase() }}"></i>
          </span>
        </li>
      </ul>
    </li>
    <!-- Table view section ends -->
    <li
      *ngIf="showSettingsInContextMenu"
      (click)="$event.preventDefault(); onSettingsClicked($event)"
      class="menu-settings"
      nz-menu-item
    >
      Settings
    </li>
  </ul>
</nz-dropdown-menu>
