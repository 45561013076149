import { Component, HostListener } from '@angular/core';

import { PerformanceService } from 'src/app/services/performance.service';
import { environment } from 'src/environments/environment';
import { DebugFlagService } from '../../../../projects/performance-tools/src/lib/debug-flag.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _performanceService: PerformanceService,
    private debugFlagService: DebugFlagService,
  ) {
    if (environment.isPerformanceInfoEnabled) {
      this._performanceService.start();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    this.debugFlagService.handleKeyboardEvent(event);
  }
}
