<window-header [window]="this"></window-header>
<div class="layout">
  <div class="menu">
    <div class="menu-list">
      <div class="d-flex align-items-center">
        <div (click)="toggleAll()" class="expand-all">
          <i [class.all-expaned]="allExpanded" class="icon-arrow-up"></i>
        </div>
        <span
          (click)="searchControl.valid && clearQuery()"
          [class.all-group-clickable]="searchControl.valid"
          class="all-group d-block mr-2"
          >All</span
        >
        <nz-input-group
          class="custom-form-control search-group"
          nzSize="small"
          [nzPrefix]="prefix"
        >
          <input
            [formControl]="searchControl"
            type="text"
            nz-input
            placeholder=""
          />
        </nz-input-group>
        <ng-template #prefix>
          <i class="icon-search"></i>
        </ng-template>
      </div>
      <cdk-virtual-scroll-viewport itemSize="" class="for-menu-list">
        <ul>
          <ng-container *ngFor="let group of groups">
            <li
              [class.expanded]="group.expanded"
              (click)="expand(group)"
              class="indicator-group-item"
            >
              <i
                class="arrow icon-arrow-down"
                [class.arrow-up]="group.expanded"
              ></i>
              {{ group.name }}
            </li>
            <div *ngIf="group.expanded">
              <div
                (click)="addIndicator(indicator)"
                class="indicator-item"
                nz-tooltip
                [nzTooltipTitle]="descriptionEnabled[indicator] && description"
                (mouseenter)="fetchDescription(indicator)"
                nzPlacement="rightTop"
                [nzTooltipOverlayClassName]="'indicator-tooltip'"
                *ngFor="let indicator of group.filteredIndicators"
              >
                {{ indicator }}
                <ng-template #description>
                  <ng-container
                    *ngFor="let section of indicatorsDescriptions[indicator]"
                  >
                    <ng-container *ngIf="section.content.length">
                      <h5>{{ section.title }}</h5>
                      <p *ngFor="let paragraph of section.content">
                        {{ paragraph }}
                      </p>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </ul>
      </cdk-virtual-scroll-viewport>
    </div>
    <div class="bottom-menu">
      <span class="current-text">Current</span>
      <cdk-virtual-scroll-viewport
        cdkDropList
        (cdkDropListDropped)="dropped($event)"
        class="mt-0"
        itemSize=""
      >
        <li
          *ngFor="let item of indicators"
          (click)="selectIndicator(item)"
          cdkDrag
          cdkDragPreviewClass=""
          class="current-indicators"
          [class.selected]="isSelected(item)"
        >
          <span [title]="item.name" class="indicator-name">{{
            item.name
          }}</span>
          <i
            (click)="$event.stopPropagation(); removeIndicator(item)"
            class="icon-bucket"
          ></i>
          <i
            (click)="$event.stopPropagation(); toggleVisible(item)"
            class="ml-auto"
            [class.icon-not-visible]="!item.visible"
            [class.icon-visible]="item.visible"
          ></i>
        </li>
      </cdk-virtual-scroll-viewport>
      <span
        [class.is-removable]="indicators.length"
        class="remove-all-text"
        (click)="removeAll()"
        >remove all</span
      >
    </div>
  </div>
  <div class="form-layout">
    <cdk-virtual-scroll-viewport
      [class]="_notifier.selectedIndicator?.instance?.name.toLowerCase()"
      itemSize=""
    >
      <formly-form
        *ngIf="_notifier.selectedIndicator"
        [form]="form"
        [(model)]="_notifier.selectedIndicator.settings"
        [fields]="_notifier.selectedIndicator.config"
      >
      </formly-form>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
