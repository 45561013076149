import { Inject, Injectable, Injector } from '@angular/core';

import { AccountsManager } from 'accounts-manager';
import {
  AlertType,
  ConnectionId,
  IBaseItem,
  Id,
  RITHMIC_INFRA_TYPE,
  WEB_SOCKET_TYPE,
  WebSocketService,
  WSEventType,
} from 'communication';
import { MessageTypes } from 'notification';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { Feed, IInstrument, OnUpdateFn, UnsubscribeFn } from 'trading';

import { Loggable } from '../../../../../src/Loggable';
import { FeedRelayService, IFeedRelayConfig } from './feed-relay.service';
import { RealtimeType } from './realtime';

export enum WSMessageTypes {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  SUBSCRIBE_L2 = 'subscribeL2',
  UNSUBSCRIBE_L2 = 'unsubscribeL2',
}

@Injectable()
export class RealFeed<T, I extends IBaseItem = any>
  extends Loggable
  implements Feed<T>
{
  static _subscriptions: {
    [connectionId: string]: {
      [instrumentId: string]: {
        count: number;
        payload: object;
      };
    };
  } = {};
  static _unsubscribeFns = {};
  static _pendingRequests = {} as any;

  public windowType: string;
  protected isChildWindow: boolean;
  protected isMainWindow: boolean;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RPROTOCOL;
  }

  get useFeedRelay(): boolean {
    return false;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: null,
    };
  }
  private _isFeedRelayInitialized: boolean = false;

  type: RealtimeType;
  infraType = RITHMIC_INFRA_TYPE.TICKER_PLANT;

  protected _webSocketService: WebSocketService;
  private _executors: OnUpdateFn<T>[] = [];

  subscribeType: WSMessageTypes;
  unsubscribeType: WSMessageTypes;

  constructor(
    protected _injector: Injector,
    @Inject(WebSocketRegistryService)
    protected _webSocketRegistryService: WebSocketRegistryService,
    @Inject(AccountsManager) protected _accountsManager: AccountsManager,
    @Inject(FeedRelayService) protected _feedRelay: FeedRelayService,
  ) {
    super();

    this._webSocketService = this._webSocketRegistryService.getWebSocketService(
      this.webSocketType,
    );
    this._webSocketService.on(
      WSEventType.Message,
      this._handleUpdate.bind(this),
    );

    this.isMainWindow = !(window as any).opener;
    this.isChildWindow = !this.isMainWindow;
    this.windowType = this.isChildWindow ? 'popup' : 'main';
  }

  private setupFeedRelay(): void {
    this._feedRelay.useConfig(this.type, this.provideFeedRelayConfig);
    if (this.isMainWindow) {
      this._feedRelay.listenToMainWindows(
        this.type,
        (event: MessageEvent): void => {
          this.log(
            `${this.windowType} received message from child window`,
            event.data,
          );
          const data: any = event.data;
          const methodName: string = data.methodName;
          const methodArgs: any = data.methodArgs;
          this[methodName](...methodArgs);
        },
      );
    } else if (this.isChildWindow) {
      this._feedRelay.listenToChildWindows(
        this.type,
        (event: MessageEvent): void => {
          const data: any = event.data.data;
          const connectionId: Id = event.data.connectionId;

          if (data?.length > 0) {
            data.forEach((item: any) => this._handleUpdate(item, connectionId));
          } else {
            this._handleUpdate(data, connectionId);
          }
        },
      );
    }
  }

  on(fn: OnUpdateFn<T>): UnsubscribeFn {
    if (!this._isFeedRelayInitialized && this.useFeedRelay) {
      this._isFeedRelayInitialized = true;
      this.setupFeedRelay();
    }
    this._executors.push(fn);

    return () => {
      this._executors = this._executors.filter(
        (executor: OnUpdateFn<T>) => executor !== fn,
      );
    };
  }

  subscribe(data: I | I[], connectionId: Id): void {
    if (this.isMainWindow) {
      this._sendRequest(this.subscribeType, data, connectionId);
      return;
    }
    if (this.isChildWindow && this.useFeedRelay) {
      this.log(
        this.constructor.name,
        'subscribe(): sending to main window',
        data,
      );
      this._feedRelay.sendToMainWindow(this.type, {
        methodName: 'subscribe',
        methodArgs: [...(Array.isArray(data) ? data : [data]), connectionId],
        origin: this.constructor.name,
      });
      return;
    }
    console.warn(this.constructor.name, 'subscribe(): not implemented', data);
  }

  unsubscribe(data: I | I[], connectionId: Id): void {
    if (this.isMainWindow) {
      this._sendRequest(this.unsubscribeType, data, connectionId);
      return;
    }

    if (this.isChildWindow && this.useFeedRelay) {
      this._feedRelay.sendToMainWindow(this.type, {
        methodName: 'unsubscribe',
        methodArgs: [...(Array.isArray(data) ? data : [data]), connectionId],
      });
      return;
    }
    console.warn(this.constructor.name, 'unsubscribe(): not implemented', data);
  }

  private _sendRequest(type: WSMessageTypes, data: I | I[], connectionId: Id) {
    const feedType = this.type;
    const items = Array.isArray(data) ? data : [data];

    items.forEach((item) => {
      if (!item) return;

      const subscriptions = RealFeed._subscriptions;
      if (!subscriptions[connectionId]) subscriptions[connectionId] = {} as any;

      if (!subscriptions[connectionId][item.id])
        subscriptions[connectionId][item.id] = {} as any;

      if (!subscriptions[connectionId][item.id][feedType])
        subscriptions[connectionId][item.id][feedType] = {} as any;

      if (type === this.subscribeType) {
        if (
          !subscriptions[connectionId][item.id][feedType]?.hasOwnProperty(
            'count',
          )
        )
          subscriptions[connectionId][item.id][feedType] = {} as any;

        const subs = subscriptions[connectionId][item.id][feedType];
        subs.count = (subs?.count || 0) + 1;

        if (subs.count === 1) {
          const dto = { Value: [item], Timestamp: new Date() };

          if (!RealFeed._unsubscribeFns[connectionId])
            RealFeed._unsubscribeFns[connectionId] = {};

          RealFeed._unsubscribeFns[connectionId][item.id] = () =>
            this._webSocketService.send(
              { Type: this.unsubscribeType, ...dto },
              connectionId,
            );
          subs.payload = dto;
          const connection = this._accountsManager.getConnection(connectionId);
          if (!connection) {
            console.error('Connection not found, ID: ' + connectionId);
          }
          this._createSubscribeRequest(connection, type, item, dto);
        }
      } else {
        const subs = subscriptions[connectionId][item.id][feedType];
        if (!subs) {
          return;
        }

        subs.count = (subs?.count || 1) - 1;

        if (subs.count === 0) {
          if (
            RealFeed._unsubscribeFns[connectionId] &&
            RealFeed._unsubscribeFns[connectionId][item.id]
          ) {
            RealFeed._unsubscribeFns[connectionId][item.id]();
            this._createUnsubscribeRequest(connectionId, item);
            delete RealFeed._unsubscribeFns[connectionId][item.id];
          }
        }
      }
    });
  }

  protected _createSubscribeRequest(connection, type, item, dto) {
    // need unsubscribe before subscription to get settle data
    // this._createUnsubscribeRequest(connection.id, item);
    // if (connection?.connected && connection.isLoggedInWithBroker)
    if (connection?.connected && connection.isLoggedInWithBroker) {
      this._webSocketService.send(
        {
          infraType: this.infraType,
          webSocketType: this.webSocketType,
          feedType: this.type,
          Type: type,
          ...dto,
        },
        connection.id,
      );
    } else {
      this.createPendingRequest(type, dto, connection.id);
    }
  }

  protected _createUnsubscribeRequest(connectionId, item) {
    RealFeed._unsubscribeFns[connectionId][item.id]();
  }

  private createPendingRequest(type, payload: any, connectionId) {
    const mapKey = this.getMapKey(connectionId);
    if (RealFeed._pendingRequests[mapKey] == null)
      RealFeed._pendingRequests[mapKey] = [];
    const types = {
      infraType: this.infraType,
      webSocketType: this.webSocketType,
      feedType: this.type,
      Type: type,
    };

    RealFeed._pendingRequests[mapKey].push(() => {
      this._webSocketService.send({ ...types, ...payload }, mapKey);
    });
  }

  protected _getHash(instrument: IInstrument, connectionId: Id) {
    return `${connectionId}/${instrument.id}`;
  }

  protected _onSuccessfullyConnect(connectionId: Id) {
    const mapKey = this.getMapKey(connectionId);

    const pendingRequests = RealFeed._pendingRequests[mapKey];
    if (!pendingRequests) return;

    pendingRequests.forEach((fn) => fn());
    RealFeed._pendingRequests[mapKey] = [];
  }

  _onDisconnect(connectionId: Id) {
    const feedType = this.type;
    const mapKey = this.getMapKey(connectionId);

    const subscriptions = RealFeed._subscriptions[connectionId];

    if (!subscriptions) {
      return;
    }

    // On disconnection, remove all previously active feed subscriptions, and add pending 'subscribe requests' that
    // will be sent when the connection is re-established.
    Object.values(RealFeed._unsubscribeFns[connectionId]).forEach(
      (callback: () => void) => callback(),
    );
    RealFeed._pendingRequests[mapKey] = [];
    Object.values(subscriptions).forEach((feeds: any) => {
      const feedSubs = feeds[feedType];
      if (!feedSubs) {
        return;
      }
      const { payload } = feedSubs;
      if (payload) {
        this.createPendingRequest(
          WSMessageTypes.SUBSCRIBE,
          payload,
          connectionId,
        );
        if (payload.Value?.length > 0) {
          payload.Value.forEach((instrumentData: any) => {
            this.unsubscribe(instrumentData, connectionId);
          });
        }
      }
    });
  }

  protected _handleUpdate(data, connectionId: Id): boolean {
    const { type, result } = data;

    if (type === 'Message') {
      if (result.value === 'Api-key accepted!') {
        if (result.source === WEB_SOCKET_TYPE.RPROTOCOL) {
          this._onSuccessfullyConnect(connectionId);
        }
      } else if (result.value === 'DrainEvent') {
        // TODO refactor this when replace ReconnectingWebSocket lib with a WebSocket implementation that supports graceful termination
        this._webSocketService.startDraining(connectionId);
        this._onDisconnect(connectionId);
      }
      return;
    }
    if (
      type === MessageTypes.CONNECT &&
      result.connectionId === ConnectionId.MarketData &&
      result.type === AlertType.ConnectionClosed
    ) {
      this._onDisconnect(connectionId);
    }

    if (type !== this.type || !result || !this._filter(result)) {
      return;
    }

    const _result = this._getResult(data);

    for (const executor of this._executors) {
      try {
        _result.connectionId = connectionId;
        executor(_result, connectionId);
      } catch (error) {
        console.error('_handleTrade', error);
      }
    }

    if (this.useFeedRelay && !this.isChildWindow) {
      this._feedRelay.broadcast(this.type, data, connectionId);
    }

    return true;
  }

  protected _getResult(data) {
    const { result } = data;
    return this._map(result);
  }

  protected _filter(item: T): boolean {
    return true;
  }

  protected _map(item: T): any {
    return item;
  }

  merge(oldItem: I, newItem: I): I {
    return newItem;
  }

  getMapKey(connectionId: Id): Id {
    if (this.infraType) {
      return `${connectionId}-${this.infraType}`;
    }
    return connectionId;
  }
}
