import { IScxComponentState, IStockChartXInstrument } from 'chart';
import { Id } from 'communication';
import { IBaseTemplate } from 'templates';
import { IAccount } from 'trading';

import { IChartSettings } from './chart-settings/settings';

declare const StockChartX: any;

export * from './models/index';
export * from './datafeed/index';

export interface IChartState extends IScxComponentState {
  isToolbarVisible: boolean;
  showOHLV: boolean;
  showChanges: boolean;
  showChartForm?: boolean;
  enableOrderForm: boolean;
  isTradingEnabled?: boolean;
  link: any;
  showOrderConfirm?: boolean;
  showCancelConfirm: boolean;
  account?: IAccount;
  preferredAccounts?: { [key in Id]: Id }; // connection ID => account ID
  instrument: IStockChartXInstrument;
  componentInstanceId: number;
  settings: IChartSettings;
  intervalOptions?: any;
  periodOptions?: any;
}
export type IChartTemplate = IBaseTemplate<IChartState>;

export type StockChartXOrderSideMarker = typeof StockChartX.OrderSideMarker;

export interface ICustomeVolumeState {
  settings: any;
}
