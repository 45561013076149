import { Injectable } from '@angular/core';

import { environment } from 'environment';
import { isInTimeRange } from 'session-manager';
import { IAccount, IInstrument, ISession, OrderSide } from 'trading';

import { Counter } from '../../../../src/app/services/counter';
import { IBar, IChart, IDetails } from '../models/chart';
import { PerformanceService } from './../../../../src/app/services/performance.service';
import { BarHandler, IBarHandler } from './bar-handlers/BarHandler';
import { BarAction } from './bar-handlers/ChartBarHandler';
import {
  BarsUpdateKind,
  IBarsRequest,
  IQuote,
  IRequest,
  IStockChartXInstrument,
  RequestKind,
} from './models';
import {
  areQuotesSorted,
  hasDuplicatePriceLevels,
  hasUnsortedPriceLevels,
  normalizeBarDetails,
  sortPriceLevels,
} from './util';

export type IDateFormat = (request: IRequest) => string;

export interface IKeyValuePair<TKey, TValue> {
  key: TKey;
  value: TValue;
}

export interface IDatafeed {
  send(request: IRequest);

  cancel(request: IRequest);
}

export interface ResponseData {
  bars: IBar[];
  additionalInfo?: any;
}

declare let StockChartX: any;

@Injectable()
export abstract class Datafeed implements IDatafeed {
  /**
   * @internal
   */
  private static _requestId = 0;

  protected _session;

  protected _historyItems: IBar[] = [];

  protected endDate;

  /**
   * @internal
   */
  private _requests = new Map<number, IRequest>();

  protected barHandler: IBarHandler;

  /**
   * @internal
   */
  protected _account: IAccount;
  protected _instrument: IInstrument;

  protected _chart: any;

  /**
   * @internal
   */
  private _quotes: IQuote[] = [];

  /**
   * @description Time of last chart update.
   */
  private _lastChartUpdate: number = window.performance.now();
  private _accumQuotesCounter: Counter<string> = new Counter<string>(
    'Real-Time Quote Accumulator Stats',
  );

  /**
   * @description Chart update interval.
   * Time needed to pass before the next update of chart should happen
   * (1 second / {@link https://www.youtube.com/watch?v=pfiHFqnPLZ4|FPS}).
   * Useful for preventing performance issues.
   */
  private _MINIMAL_CHART_UPDATE_INTERVAL: number = 250;

  private performanceService: PerformanceService =
    window.injector.get(PerformanceService);
  private _lastDetailsNormalizationTime: number;

  setSession(session: ISession, chart): void {
    const oldSession = this._session;
    this._session = session;
    if (oldSession?.id !== session?.id) {
      this._recalculateData(chart);
    }
  }

  private _recalculateData(chart) {
    if (!this.barHandler) return;

    chart.dataManager.clearBarDataSeries();
    const historyItems = [];
    for (let i = 0; i < this._historyItems.length; i++) {
      const historyItem = this._historyItems[i];
      if (isInTimeRange(historyItem.date, this._session)) {
        historyItems.push(historyItem);
      }
    }
    chart.dataManager.appendBars(this.barHandler.processBars(historyItems));
    chart.setNeedsUpdate(true);
  }

  /**
   * Executes request post cancel actions (e.g. hides waiting bar).
   * @method onRequstCanceled
   * @memberOf StockChartX.Datafeed#
   * @protected
   */
  protected onRequstCanceled(request: IBarsRequest) {
    if (this._requests.size === 0) request.chart.hideWaitingBar();
  }

  /**
   * Executes request post complete actions (e.g. hides waiting bar, updates indicators, refreshes chart).
   * @method onRequestCompleted
   * @memberOf StockChartX.Datafeed#
   * @protected
   */
  protected onRequestCompleted(
    request: IBarsRequest,
    { bars, additionalInfo }: ResponseData,
  ) {
    this._requests.delete(request.id);

    const chart = request.chart;
    this._chart = chart;
    const dataManager = chart.dataManager;
    const oldPrimaryBarsCount =
      request.kind === RequestKind.MORE_BARS
        ? chart.primaryBarDataSeries().low.length
        : 0;
    const instrument = chart.instrument;

    if (!this.barHandler) {
      this.barHandler = new BarHandler(request.chart);
    }

    switch (request.kind) {
      case RequestKind.BARS: {
        dataManager.clearBarDataSeries();
        this._historyItems = bars;
        this.barHandler.clear();
        const filteredBars = bars.filter((bar) =>
          isInTimeRange(bar.date, this._session),
        );
        const processBars = this.barHandler.processBars(filteredBars);
        const lastHistoricalBar: IBar =
          processBars?.length > 0 ? processBars[processBars.length - 1] : null;
        this.barHandler.setAdditionalInfo(additionalInfo);
        request.chart.dataManager.appendBars(processBars);
        const endTime = this.endDate?.getTime() ?? 0;
        if (Array.isArray(this._quotes)) {
          const firstAccRealTimeQuote: IQuote = this._quotes[0];
          const lastAccRealTimeQuote: IQuote =
            this._quotes[this._quotes.length - 1];
          const isSorted: boolean = areQuotesSorted(this._quotes);

          console.log(
            `Datafeed: processing accumulated ${this._quotes.length} quotes, sorted: ${isSorted}`,
            {
              lastHistoricalBar,
              firstAccRealTimeQuote,
            },
          );
          this._accumQuotesCounter.setCount(
            'accum_quotes_to_be_processed',
            this._quotes.length,
          );
          let i = 0;
          for (const quote of this._quotes) {
            i++;
            const isOverlapping: boolean =
              quote.date.getTime() <= lastHistoricalBar?.date?.getTime();
            if (!isOverlapping) {
              this._accumQuotesCounter.incrementCount(
                'accum_quotes_processed_successfully',
              );
              if (!quote.notes) quote.notes = [];
              quote.notes.push(
                `${performance.now()} - ${new Date()} - passing accumulated quote to be processed`,
              );
              this.processQuote(request, quote);
            } else {
              this._accumQuotesCounter.incrementCount(
                'accum_quotes_skipped_due_overlapping',
              );
            }
          }
          // this._accumQuotesCounter.displayStats(); // @todo control this using Performance Tools panel via a checkbox
        }
        this._quotes = [];
        this._accumQuotesCounter.resetAllCounts();
        this._accumQuotesCounter.stopDisplayingStats();

        // Sanity check
        const isDebugMode = (window as any).debug;
        if (isDebugMode) {
          const detailsDataSeries =
            dataManager?._dataSeries['.details']?._values;
          if (detailsDataSeries) {
            const totalBars = detailsDataSeries.length;
            const hasDups = hasDuplicatePriceLevels(
              detailsDataSeries[totalBars - 1],
            );
            const isUnsorted = hasUnsortedPriceLevels(
              detailsDataSeries[totalBars - 1],
            );
            console.log(
              `Last Bar's Details: isUnsorted: ${isUnsorted} hasDups: ${hasDups}`,
            );
          }
        }

        break;
      }
      /*   case RequestKind.MORE_BARS: {
           this.barHandler.clear();
           this._historyItems = [...bars, ...this._historyItems];
           const preparedBars = this.barHandler.prependBars(
             bars.filter(bar => isInTimeRange(bar.date, this._session?.workingTimes)));
           request.chart.dataManager.insertBars(0, preparedBars);
           break;
         }*/
      default:
        throw new Error(`Unknown request kind: ${request.kind}`);
    }
    const newLength = chart.primaryBarDataSeries().low.length;
    let barsCount = newLength - oldPrimaryBarsCount;
    if (instrument) {
      barsCount = Math.round(chart.lastVisibleIndex - chart.firstVisibleIndex);
    }

    // if !instrument then load bars for chart, not for compare
    if (instrument) {
      if (request.kind === RequestKind.BARS) {
        let min = Math.max(0, newLength - 100);
        if (min >= newLength) {
          min = 0;
        }
        if (newLength > 0) chart.recordRange(min, newLength);
      } else if (request.kind === RequestKind.MORE_BARS) {
        chart.firstVisibleRecord = barsCount < 0 ? 0 : newLength - barsCount;
        chart.lastVisibleRecord = newLength;
      }
    }

    chart.fireValueChanged(StockChartX.ChartEvent.HISTORY_LOADED, {
      request,
      bars,
    });

    chart.hideWaitingBar();
    chart.updateIndicators();
    chart.setNeedsAutoScale();
    chart.updateComputedDataSeries();
    chart.dateScale.onMoreHistoryRequestCompleted();
    setTimeout(() => {
      chart.setNeedsUpdate(true);
    }, 30);
  }

  // region IDatafeed members

  /**
   * Sends request to the datafeed provider.
   * @method send
   * @param {StockChartX~Request} request The processing request.
   * @memberOf StockChartX.Datafeed#
   */
  send(request: IRequest) {
    if (request.kind === 'bars') request.chart.dataManager.clearBarDataSeries();

    this._requests.set(request.id, request);

    request.chart.showWaitingBar();
  }

  /**
   * Cancels request processing.
   * @method cancel
   * @param {StockChartX~Request} request The cancelling request.
   * @memberOf StockChartX.Datafeed#
   */
  cancel(request: IRequest): void {
    this._requests.delete(request.id);
    this.onRequstCanceled(request as IBarsRequest);
  }

  changeAccount(account: IAccount): void {
    this._account = account;
  }

  changeInstrument(instrument: IInstrument): void {
    this._instrument = instrument;
  }

  /**
   * Destroy request.
   * @method destroy
   * @param {StockChartX~Request}.
   * @memberOf StockChartX.Datafeed#
   */
  destroy() {
    this._requests.clear();
  }

  // endregion

  /**
   * Determines whether request is alive.
   * @method isRequestAlive
   * @param {StockChartX~Request} request The request.
   * @memberof StockChartX.Datafeed#
   * @returns {boolean} True if request is alive, otherwise false.
   */
  isRequestAlive(request: IRequest): boolean {
    return this._requests.has(request.id);
  }

  private _accumulateQuotes(quote: IQuote) {
    this._accumQuotesCounter.incrementCount('quotes_accumulated');
    if (!quote.notes) quote.notes = [];
    quote.notes.push(
      `${performance.now()} - ${new Date()} - queued as an accumulated quote`,
    );
    this._quotes.push(quote);
  }

  protected processQuote(request: IRequest, quote: IQuote): void {
    let currentTime: number;
    let barAction: BarAction;
    let instrument: IStockChartXInstrument;

    if (quote?.instrument?.symbol == null) {
      return;
    }

    const { chart } = request;

    if (!this.barHandler) {
      this.barHandler = new BarHandler(chart);
    }

    const isChildWindow: boolean = !!(window as any).opener;
    if (
      !isChildWindow &&
      request.kind === 'bars' &&
      this.isRequestAlive(request)
    ) {
      this._accumulateQuotes(quote);

      return;
    }

    /**
     * If instrument is null then you operate with main bars otherwise
     * with others bars data-series (compare instruments).
     */
    instrument =
      chart.instrument.id === quote.instrument.id ? null : chart.instrument;

    if (!this._getLastBar(chart, instrument)) {
      return;
    }

    barAction = this._processBar({
      open: quote.price,
      high: quote.price,
      low: quote.price,
      close: quote.price,
      volume: quote.volume,
      date: new Date(quote.date),
    });

    if (barAction === BarAction.Add) {
      chart.applyAutoScroll(BarsUpdateKind.NEW_BAR);
    } else if (barAction === BarAction.Update) {
      chart.applyAutoScroll(BarsUpdateKind.TICK);
    }

    this._updateLastBarDetails(quote, chart, instrument);

    currentTime = window.performance.now();

    if (
      isChartUpdateAllowed(
        currentTime,
        this._lastChartUpdate,
        this._MINIMAL_CHART_UPDATE_INTERVAL,
      )
    ) {
      this._lastChartUpdate = currentTime;
      requestAnimationFrame(this._updateComputedDataSeries);
    } else if (environment.isPerformanceInfoEnabled) {
      this.performanceService.addChartUpdatePreventionInfo();
    }
  }

  _updateComputedDataSeries = (): void => {
    const chart = this._chart;

    chart.updateIndicators();
    chart.updateComputedDataSeries();
    chart.setNeedsUpdate();
    chart.setNeedsLayout();
  };

  private _processBar(bar: IBar): BarAction {
    let barResult: { action: BarAction; bar: IBar };
    let lastBar: IBar;
    let barData: IBar;

    if (!isInTimeRange(bar.date, this._session)) {
      this._historyItems.push(bar);
      return BarAction.None;
    }

    barResult = this.barHandler.processBar(bar);

    if (barResult?.action === BarAction.Add) {
      this._historyItems.push(barResult.bar);
      return BarAction.Add;
    }

    if (barResult?.action === BarAction.Update) {
      barData = barResult.bar;
      lastBar = {
        ...this._historyItems[this._historyItems.length - 1],
        close: barData.close,
      };

      if (barData.high > lastBar.high) {
        lastBar.high = barData.high;
      }

      if (barData.low < lastBar.low) {
        lastBar.low = barData.low;
      }

      lastBar.volume += barData.volume;
      this._historyItems[this._historyItems.length - 1] = lastBar;

      return BarAction.Update;
    }
  }

  private _updateLastBarDetails(
    quote: IQuote,
    chart: IChart,
    instrument?: IStockChartXInstrument,
  ): void {
    const symbol =
      instrument && instrument.symbol !== chart.instrument.symbol
        ? instrument.symbol
        : '';
    const barDataSeries = chart.dataManager.barDataSeries(symbol);
    const detailsDataSeries = barDataSeries.details;
    if (!detailsDataSeries) return;

    let lastDetails = detailsDataSeries.lastValue as IDetails[];
    if (!lastDetails) {
      detailsDataSeries.setValue(detailsDataSeries.length - 1, []);
      lastDetails = detailsDataSeries.lastValue as IDetails[];
    }

    let details = handleNewQuote(quote, lastDetails);

    const _instrument = instrument || chart.instrument;

    // Run normalization only once every x ms
    if (!this._lastDetailsNormalizationTime)
      this._lastDetailsNormalizationTime = performance.now();
    const delta = performance.now() - this._lastDetailsNormalizationTime;
    const MIN_NORMALIZATION_INTERVAL =
      (window as any).MIN_NORMALIZATION_INTERVAL || 20;
    if (delta > MIN_NORMALIZATION_INTERVAL) {
      this._lastDetailsNormalizationTime = performance.now();
      details = normalizeBarDetails(
        details,
        _instrument.tickSize,
        _instrument.precision,
      );
      // TODO check whether deduplication is also necessary or useful at this level
    }

    detailsDataSeries.updateLast(details);
  }

  protected _getLastBar(
    chart: IChart,
    instrument?: IStockChartXInstrument,
  ): IBar {
    return chart.dataManager.getLastBar(instrument);
  }
}

/**
 * @description Tells if chart update is allowed.
 * Prevents chart update if not enough time has passed since last update.
 * Useful for preventing performance issues.
 */
export function isChartUpdateAllowed(
  currentTime: number,
  lastChartUpdate: number,
  minimalChartUpdateInterval: number,
): boolean {
  return currentTime - lastChartUpdate > minimalChartUpdateInterval
    ? true
    : false;
}

function handleNewQuote(quote: IQuote, details: IDetails[]): IDetails[] {
  const { volume, tradesCount: _tradesCount, price } = quote;
  const tradesCount: number = _tradesCount ?? volume;
  let item: IDetails = details.find((i) => i.price === price);

  if (!item) {
    item = {
      bidInfo: {
        volume: 0,
        tradesCount: 0,
      },
      askInfo: {
        volume: 0,
        tradesCount: 0,
      },
      volume,
      tradesCount,
      price,
      notes: [],
    };

    details.push(item);

    if (hasUnsortedPriceLevels(details)) {
      details = sortPriceLevels(details);
    }
  }

  switch (quote.side) {
    case OrderSide.Sell:
      item.bidInfo.volume += volume;
      item.bidInfo.tradesCount += tradesCount;
      break;
    case OrderSide.Buy:
      item.askInfo.volume += volume;
      item.askInfo.tradesCount += tradesCount;
      break;
  }

  if (quote.notes) {
    if (!item.notes) item.notes = [];
    item.notes.push(quote.notes);
  }

  item.volume += volume;
  item.tradesCount += tradesCount;

  return details;
}
