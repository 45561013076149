import { Injectable } from '@angular/core';

import { IInstrument, VolumeData } from 'trading';

import { IFeedRelayConfig } from './feed-relay.service';
import { RealFeed, WSMessageTypes } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealVolumeDatafeed extends RealFeed<VolumeData, IInstrument> {
  type = RealtimeType.VolumePrint;
  get useFeedRelay(): boolean {
    return true;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: 30,
    };
  }

  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;
}
