import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { LogConfig, Loggable } from '../../../../src/Loggable';

export enum MetricName {
  TradeLatency = 'tradeLatency',
  AverageTradeLatency = 'averageTradeLatency',
  DOMTradeLatency = 'domTradeLatency',
  DOMAverageTradeLatency = 'domAverageTradeLatency',
  MessagesPerSec = 'messagesPerSec',
  AverageMessagesPerSec = 'averageMessagesPerSec',
  PeakMessagesPerSec = 'peakMessagesPerSec',
  WebSocketOrderPlantLatency = 'webSocketOrderPlantLatency',
  WebSocketTickerPlantLatency = 'webSocketTickerPlantLatency',
}

export interface IMetric {
  name: MetricName;
  value: number;
}

export interface IReporterConfig {
  intervalId: number;
  instance: any;
}

export interface IMetricReporter {
  collectMetrics(): IMetric[];
  resetMetrics(): void;
}

@Injectable({
  providedIn: 'root',
})
export class PerformanceMetricsService extends Loggable {
  public logConfig: LogConfig = {
    enabled: false,
    configureMetadata: (args: string[]) => {},
  };

  private metrics: { [key: string]: BehaviorSubject<IMetric | null> } = {};
  private activeReporters: Map<any, IReporterConfig> = new Map<
    any,
    IReporterConfig
  >();

  constructor() {
    super();
  }

  // Method to report a metric
  reportMetric(metricName: MetricName, value: number): void {
    if (!this.metrics[metricName]) {
      this.metrics[metricName] = new BehaviorSubject<IMetric | null>(null);
    }
    const metric: IMetric = { name: metricName, value };
    this.metrics[metricName].next(metric);
  }

  // Report multiple metrics at once
  reportMetrics(metrics: IMetric[]): void {
    metrics.forEach(({ name, value }) => {
      this.reportMetric(name, value);
    });
  }

  // Observable to subscribe to a specific metric
  getMetricUpdates(metricName: MetricName): Observable<IMetric | null> {
    if (!this.metrics[metricName]) {
      this.metrics[metricName] = new BehaviorSubject<IMetric | null>(null);
    }
    return this.metrics[metricName].asObservable();
  }

  // Observable to subscribe to multiple metrics at once
  getMultipleMetricUpdates(
    metricNames: MetricName[],
  ): Observable<{ [key: string]: IMetric | null }> {
    const metricObservables: Observable<IMetric | null>[] = metricNames.map(
      (name: MetricName) => this.getMetricUpdates(name),
    );

    return combineLatest(metricObservables).pipe(
      map((values: IMetric[]) => {
        const result: { [key: string]: IMetric | null } = {};
        metricNames.forEach((name: MetricName, index: number) => {
          result[name] = values[index];
        });
        return result;
      }),
      distinctUntilChanged((prev, curr) => {
        return metricNames.every(
          (name: MetricName) => prev[name]?.value === curr[name]?.value,
        );
      }),
    );
  }

  destroy(): void {}

  registerReporter(reporter: IMetricReporter): void {
    if (this.activeReporters.has(reporter)) {
      return;
    }
    const reporterType: string = reporter.constructor.name;
    // Prevents multiple reporters of the same type from being registered
    if (
      Array.from(this.activeReporters.values()).some(
        (config: IReporterConfig) =>
          config.instance.constructor.name === reporterType,
      )
    ) {
      return;
    }
    const intervalId: number = setInterval(() => {
      const metrics: IMetric[] = reporter.collectMetrics();
      if (!metrics?.length) return;
      this.reportMetrics(metrics);
    }, 1000);

    this.activeReporters.set(reporter, {
      intervalId,
      instance: reporter,
    } as IReporterConfig);
  }

  unregisterReporter(reporterInstance: IMetricReporter): void {
    const reporterConfig: IReporterConfig =
      this.activeReporters.get(reporterInstance);
    if (!reporterConfig) {
      return;
    }
    clearInterval(reporterConfig.intervalId);
    this.activeReporters.delete(reporterInstance);
  }

  resetMetrics(): void {
    // Make a copy of the active reporters
    const reporters: IReporterConfig[] = Array.from(
      this.activeReporters.values(),
    );

    // Unregister all reporters before resetting metrics, then later re-register them back
    Array.from(this.activeReporters.keys()).forEach((reporterInstance: any) => {
      this.unregisterReporter(reporterInstance);
    });

    Object.entries(this.metrics).forEach(
      ([metricName, metric]: [MetricName, BehaviorSubject<IMetric | null>]) =>
        metric.next({ name: metricName, value: 0 } as IMetric),
    );

    reporters.forEach((reporterConfig: IReporterConfig) => {
      reporterConfig.instance.resetMetrics();
    });

    // Re-register reporters
    reporters.forEach((reporterConfig: IReporterConfig) => {
      this.registerReporter(reporterConfig.instance);
    });
  }

  async copyMetricsToClipboard(): Promise<void> {
    const metrics: { [key: string]: number } = {};
    Object.entries(this.metrics).forEach(
      ([metricName, metric]: [MetricName, BehaviorSubject<IMetric | null>]) => {
        metrics[metricName] = metric.value?.value || 0;
      },
    );
    return navigator.clipboard.writeText(JSON.stringify(metrics, null, '  '));
  }
}
