import Events from './emitter';
import { html } from './html';

const BASE_WINDOW_CLASS = 'wm-window';
const regex = new RegExp(/resize-?\S*(|$)/gi);

const ResizeDirections = {
    Up: 'up',
    Down: 'down',
    Left: 'left',
    Right: 'right',
}

const ActiveClass = {
    Resizing: 'resizing',
    Moving: 'moving',
}

const RESIZE_PREFIX = 'resize'

const Class = {
    Active: 'active',
    Maximized: 'window-maximized',
}
Object.freeze(Class);

const ResizingSencetive = 3;

/**
 * Window class returned by WindowManager.createWindow()
 * @extends EventEmitter
 * @fires open
 * @fires focus
 * @fires blur
 * @fires close
 * @fires maximize
 * @fires maximize-restore
 * @fires move
 * @fires move-start
 * @fires move-end
 * @fires resize
 * @fires resize-start
 * @fires resize-end
 * @fires move-x
 * @fires move-y
 * @fires resize-width
 * @fires resize-height
 */
export class Window extends Events {
    get z() {
        return parseInt(this._container.style.zIndex)
    }

    set z(value) {
        this._container.style.zIndex = value
    }

    set visible(value) {
        this._container.style.display = value ? "block": "none";
        this._visible = value;
        this.emit(value ? "makeVisible" : "makeInvisible", this);
        this.minimized = !value;
        this.wm.setContainerMinSize();
    }

    get visible() {
      return this._visible;
    }

    /**
     * @param {WindowManager} [wm]
     * @param {object} [options]
     */
    constructor(wm, options = {}) {
        super()
        this.wm = wm
        this.options = options
        this._visible = false;
        this.id = typeof this.options.id === 'undefined' ? `${Date.now()}_${Math.random()}` : this.options.id
        this._createWindow()

        this.globalOffset = options.globalOffset;

        this.resizable = options.resizable;

        this.active = false;

        this.maximized = false;
        this.minimized = false;
        this.minimizable = options.minimizable;
        this.maximizable = options.maximizable;
        this.minWidth = options.minWidth;
        this.minHeight = options.minHeight;
        this.allowPopup = options.allowPopup;
        this.closableIfPopup = options.closableIfPopup;
        this.visible = options.hasOwnProperty("visible") ? options.visible : false;

        if (this.options.maximized) {
            const { x, y, width, height } = this.options.maximized;

            this.x = x || 0;
            this.y = y || 0;
            this.width = width || parseInt(this.options.minWidth);
            this.height = height || parseInt(this.options.minHeight);

            this.maximize();
        }

        if (this.options.minimized)
            this.minimize()

        this._moving = null
        this._prevPosition = null
        this._resizing = null


        this._hoverState = null;
        this._state = null;

        this._attachedToScreen = { vertical: '', horziontal: '' }
        this.ignoreOffset = wm.options.snapIgnoreOffset || 10;
    }

    /**
     * open the window
     */
    open() {
        this.emit('open', this)
    }

    /**
     * focus the window
     */
    focus(handle = true) {
        this.active = true;
        this._container.classList.add(Class.Active);
        if (handle)
            this.wm.handleFocus(this);

        this.emit('focus', this)
    }

    /**
     * blur the window
     */
    blur() {
        this.active = false;
        this._container.classList.remove(Class.Active);
        this.wm.handleBlur(this);
        this.emit('blur', this)
    }

    /**
     * closes the window
     */
    close() {
        this.wm._close(this);
        this.emit('close', this)
    }

    /**
     * left coordinate
     * @type {number}
     */
    get x() { return this.options.x }
    set x(value) {
        if (value !== this.options.x) {
            this.options.x = value - this.globalOffset.left;
            this.emit('move-x', this)
            this._buildTransform()
        }
    }

    _buildTransform() {
        const bounds = this.wm.bounds;

        const top = bounds && bounds.top || 0;
        const left = bounds && bounds.left || 0;
        // this._container.style.transform = `translate(${this.options.x - left}px,${this.options.y - top}px)`
        this._container.style.transform = `translate(${this.options.x}px,${this.options.y}px)`
        // this._container.style.top = `${this.options.y}px`
        // this._container.style.left = `${this.options.x}px`
    }

    /**
     * top coordinate
     * @type {number}
     */
    get y() { return this.options.y }
    set y(value) {
        if (value !== this.options.y) {
            this.options.y = value - this.globalOffset.top;
            this._buildTransform()
            this.emit('move-y', this)
        }
    }

    /**
     * width of window
     * @type {number}
     */
    get width() { return this.options.width || this._container.offsetWidth }
    set width(value) {

        if (value !== this.options.width) {
            if (value) {
                this._container.style.width = `${value}px`
                this.options.width = value;
            }
            else {
                this._container.style.width = 'auto'
                this.options.width = ''
            }
            this.emit('resize-width', this)
        }
    }

    /**
     * height of window
     * @type {number}
     */
    get height() { return this.options.height || this._container.offsetHeight }
    set height(value) {

        if (value !== this.options.height) {
            if (value) {
                this._container.style.height = `${value}px`
                this.options.height = value;
            }
            else {
                this._container.style.height = 'auto'
                this.options.height = ''
            }
            this.emit('resize-height', this)
        }
    }

    /**
     * resize the window
     * @param {number} width
     * @param {number} height
     */
    resize(width, height) {
        this.width = width
        this.height = height
    }

    /**
     * move window
     * @param {number} x
     * @param {number} y
     */
    move(x, y) {
        const keepInside = this.options.keepInside
        const bounds = this.bounds || this.wm.bounds;

        if (keepInside) {

            if (keepInside === true || keepInside === 'horizontal' || keepInside.right === true) {
                x = x + this.width > bounds.right ? bounds.right - this.width : x
            }
            if (keepInside === true || keepInside === 'horizontal' || keepInside.left === true) {
                x = x < bounds.left ? bounds.left : x
            }
            if (keepInside === true || keepInside === 'vertical' || keepInside.bottom === true) {
                y = y + this.height > bounds.bottom ? bounds.bottom - this.height : y
            }
            if (keepInside === true || keepInside === 'vertical' || keepInside.top === true) {
                y = y < bounds.top ? bounds.top : y
            }
        }

        if (x !== this.options.x) {
            this.options.x = x
            this.emit('move-x', this)
        }
        if (y !== this.options.y) {
            this.options.y = y
            this.emit('move-y', this)
        }
        this._buildTransform()
    }

    /**
     * maximize the window
     */
    maximize() {
        if (this.options.maximizable) {
            if (this.maximized) {
                const x = this.maximized.x + this.globalOffset.left;
                const y = this.maximized.y + this.globalOffset.top;
                this.width = this.maximized.width;
                this.height = this.maximized.height;
                this.move(x, y);
                this.maximized = null
                this._container.classList.remove(Class.Maximized);

                this.emit('restore', this)
            }
            else {
                const x = this.x
                const y = this.y

                const width = this._container.clientWidth;
                const height = this._container.clientHeight;
                this._container.classList.add(Class.Maximized);


                this.maximized = { x, y, width, height }

                this.x = 0 + this.globalOffset.left
                this.y = 0 + this.globalOffset.top

                const bounds = this.wm.bounds;
                const windowBounds = this.bounds || {left: 0, top: 0, right: 0, bottom: 0};
                this.width = this.wm.container.clientWidth - windowBounds.left;
                this.height = this.wm.container.clientHeight - windowBounds.top;

                this.move(bounds.left, bounds.top);
                this.emit('maximize', this);
            }
        }
    }

    minimize() {
        if (this.options.minimizable) {
            if (this.minimized) {
                this.minimized = false;
                this._container.style.display = 'flex';
                this.emit('show', this)
            }
            else {
                this._container.style.display = 'none';
                this.minimized = true;
                this.blur();
                this.emit('hide', this);
                this.emit('minimize', this);
            }
        }
    }

    /**
     * sends window to back of window-manager
     */
    sendToBack() {
        this.wm.sendToBack(this)
    }

    /**
     * send window to front of window-manager
     */
    sendToFront() {
        this.wm.sendToFront(this)
    }

    /**
     * save the state of the window
     * @return {object} data
     */
    save() {
        const data = {}

        data.id = this.id;

        const maximized = this.maximized
        const minimized = this.minimized
        data.maximizable = this.maximizable;
        data.minimizable = this.minimizable;
        data.resizable = this.resizable;
        data.minWidth = this.minWidth;
        data.minHeight = this.minHeight;
        data.allowPopup = this.allowPopup;
        data.closableIfPopup = this.closableIfPopup;

        data.visible = this.visible;

        if (maximized) {
            data.maximized = { left: maximized.left, top: maximized.top, width: maximized.width, height: maximized.height }
        }

        if (minimized) {
            data.minimized = minimized;
        }

        data.x = this.x
        data.y = this.y
        if (typeof this.options.width !== 'undefined') {
            data.width = this.options.width
        }
        if (typeof this.options.height !== 'undefined') {
            data.height = this.options.height
        }

        const componentState = this.options.componentState;
        data.component = typeof componentState === 'function' ? componentState() : componentState;
        return data
    }

    /**
     * return the state of the window
     * @param {object} data from save()
     */
    load(data) {
        if (data.maximized) {
            if (!this.maximized) {
                this.maximize(true)
            }
        }

        if (data.minimized) {
            this.minimize()
        }

        else if (this.maximized) {
            this.maximize(true)
        }
        this.x = data.x + this.globalOffset.left
        this.y = data.y + this.globalOffset.top

        if (typeof data.width !== 'undefined') {
            this.width = data.width
        }
        else {
            this._container.style.width = 'auto'
        }
        if (typeof data.height !== 'undefined') {
            this.height = data.height
        }
        else {
            this._container.style.height = 'auto'
        }

        this.open()
    }

    /**
     * change title
     * @type {string}
     */
    get title() { return this._title }
    set title(value) {
        this.emit('title-change', this)
    }


    /**
     * right coordinate of window
     * @type {number}
     */
    get right() { return this.x + this.width }
    set right(value) {
        this.x = value - this.width
    }

    /**
     * bottom coordinate of window
     * @type {number}
     */
    get bottom() { return this.y + this.height }
    set bottom(value) {
        this.y = value - this.height
    }

    /**
     * centers window in middle of other window or document.body
     * @param {Window} [win]
     */
    center(win) {
        if (win) {
            this.move(
                win.x + win.width / 2 - this.width / 2,
                win.y + win.height / 2 - this.height / 2
            )
        }
        else {
            this.move(
                window.innerWidth / 2 - this.width / 2,
                window.innerHeight / 2 - this.height / 2
            )
        }
    }

    /**
     * Fires when window is maximized
     * @event Window#maximize
     * @type {Window}
     */

    /**
     * Fires when window is restored to normal after being maximized
     * @event Window#maximize-restore
     * @type {Window}
     */

    /**
     * Fires when window opens
     * @event Window#open
     * @type {Window}
     */

    /**
     * Fires when window gains focus
     * @event Window#focus
     * @type {Window}
     */
    /**
     * Fires when window loses focus
     * @event Window#blur
     * @type {Window}
     */
    /**
     * Fires when window closes
     * @event Window#close
     * @type {Window}
     */

    /**
     * Fires when resize starts
     * @event Window#resize-start
     * @type {Window}
     */

    /**
     * Fires after resize completes
     * @event Window#resize-end
     * @type {Window}
     */

    /**
     * Fires during resizing
     * @event Window#resize
     * @type {Window}
     */

    /**
     * Fires when move starts
     * @event Window#move-start
     * @type {Window}
     */

    /**
     * Fires after move completes
     * @event Window#move-end
     * @type {Window}
     */

    /**
     * Fires during move
     * @event Window#move
     * @type {Window}
     */

    /**
     * Fires when width is changed
     * @event Window#resize-width
     * @type {Window}
     */

    /**
     * Fires when height is changed
     * @event Window#resize-height
     * @type {Window}
     */

    /**
     * Fires when x position of window is changed
     * @event Window#move-x
     * @type {Window}
     */


    /**
     * Fires when y position of window is changed
     * @event Window#move-y
     * @type {Window}
     */

    _createWindow() {
        /**
         * This is the top-level DOM element
         * @type {HTMLElement}
         * @readonly
         */
        const winStyles = {
            'user-select': 'none',
            // 'overflow': 'hidden',
            'position': 'absolute',
            'min-width': this.options.minWidth,
            'min-height': this.options.minHeight,
            'width': isNaN(this.options.width) ? this.options.width : this.options.width + 'px',
            'height': isNaN(this.options.height) ? this.options.height : this.options.height + 'px',
            ...this.options.styles
        }

        this._container = html({
            parent: (this.wm ? this.wm.container : null),
            styles: winStyles,
            className: ((this.options.classNames.container || '') + ' ' + BASE_WINDOW_CLASS).trim(),
        })

        this._buildTransform();
    }

    setTitle(value) {
        this.title = value;
    }

    _getHitTestState(point) {

        const offset = ResizingSencetive;
        const x = point.x - this.globalOffset.left;
        const y = point.y - this.globalOffset.top;

        const up = Math.abs(y - this.y) < offset;
        const down = Math.abs(y - (this.y + this.height)) < offset;
        const left = Math.abs(x - this.x) < offset;
        const right = Math.abs(x - (this.x + this.width)) < offset;

        let horizontal;
        let vertical;

        if (up) {
            vertical = ResizeDirections.Up;
        } else if (down) {
            vertical = ResizeDirections.Down;
        } else {
            vertical = null;
        }

        if (left) {
            horizontal = ResizeDirections.Left;
        } else if (right) {
            horizontal = ResizeDirections.Right;
        } else {
            horizontal = null;
        }

        if (horizontal || vertical) {
            return {
                horizontal,
                vertical,
            }
        }

        var _offset = 0;

        if (
            x >= (this.x - _offset) &&
            x <= ((this.x + this.width + _offset)) &&
            y >= (this.y - _offset) &&
            y <= ((this.y + this.height + _offset))
        ) return { moving: true };

        return null;
    }

    handleMovePoint(point, bindings) {
        if (this.minimized)
            return;

        this.bindings = bindings;

        if (this.maximized)
            return;

        if (this._state) {
            const state = this._state;

            let dx = this._prevPosition ? point.x - this._prevPosition.x : null;
            let dy = this._prevPosition ? point.y - this._prevPosition.y : null;
            let ignoreDx = false;
            let ignoreDy = false;

            if (bindings) {

                const isMoving = state.moving != null;
                const handleTop = isMoving || state.vertical === ResizeDirections.Up;
                const handleBottom = isMoving || state.vertical === ResizeDirections.Down;
                const handleLeft = isMoving || state.horizontal === ResizeDirections.Left;
                const handleRight = isMoving || state.horizontal === ResizeDirections.Right;

                if (((handleTop && bindings.top === this.y) || (handleBottom && bindings.bottom === this.y + this.height)) && dy !== 0 && Math.abs(dy) < this.ignoreOffset) {
                    ignoreDy = true;
                    dy = 0;
                }
                if (((handleLeft && bindings.left === this.x) || (handleRight && bindings.right === this.x + this.width)) && dx !== 0 && Math.abs(dx) < this.ignoreOffset) {
                    ignoreDx = true;
                    dx = 0;
                }
            }

            if (dx == null || dy == null || (ignoreDx && ignoreDy)) {
                if (!this._prevPosition)
                    this._prevPosition = point;
                return;
            }
            else if (ignoreDx)
                this._prevPosition.y = point.y;
            else if (ignoreDy)
                this._prevPosition.x = point.x;
            else
                this._prevPosition = point;

            if (state.moving) {
                this.move(
                    this.x + dx,
                    this.y + dy,
                );

                this.emit('move', this)
                if (!this._container.classList.contains(ActiveClass.Moving))
                    this._container.classList.add(ActiveClass.Moving);
                return true;

            } else if (state.horizontal || state.vertical) {
                if (!this.isResize && this.resizable) {
                    this.isResize = true;

                    const shifts = this._getShifts(point, state);
                    const parentCords = this.wm.container.getBoundingClientRect();

                    const data = {
                        startWidth: this.width,
                        startHeight: this.height,
                        startX: point.x - shifts.shiftX,
                        startY: point.y - shifts.shiftY,
                        ignoreDx,
                        ignoreDy,
                        parentCords,
                        state,
                    }

                    const resize = this._resize.bind(this, data);

                    document.onmouseup = () => {
                        this.isResize = false;

                        document.removeEventListener('mousemove', resize);
                        document.onmouseup = null;
                    };

                    document.addEventListener('mousemove', resize);
                }

                if (!this._container.classList.contains(ActiveClass.Resizing))
                    this._container.classList.add(ActiveClass.Resizing)
                return true
            }
        } else {
            this._hoverState = this._getHitTestState(point);

            const state = this._hoverState;

            if (!this.resizable)
                return;

            const container = this._container;

            if (this._hoverState == null) {
                container.className = container.className.replace(regex, '').trim();
            } else {
                const newClass = [RESIZE_PREFIX, state.horizontal, state.vertical].filter(Boolean).join('-');

                if (container.className.includes(RESIZE_PREFIX))
                    container.className = container.className.replace(regex, newClass).trim();
                else
                    container.className += ` ${newClass}`;
            }
        }
    }

    handleMouseup() {
        if (this.minimized)
            return;

        this._state = null;
        this._prevPosition = null;
        this._container.classList.remove(ActiveClass.Resizing, ActiveClass.Moving);
    }

    handleMousedown(event, point) {
        if (this.minimized)
            return;

        const { target } = event;

        if (!this._hoverState || !this.hitTest(point) || !this.dragTest(target))
            return false;

        this._state = this._hoverState;
        this._hoverState = null;

        return true;
    }

    _resize(data, event) {
        if (this.minimized)
            return;

        const bindings = this.bindings;

        const { clientX, clientY } = event;

        const { top: offsetTop, left: offsetLeft } = this.globalOffset;

        const {
            startWidth,
            startHeight,
            startX,
            startY,
            state: {
                horizontal,
                vertical
            },
            parentCords,
        } = data;

        const minWidth = parseInt(this.options.minWidth);
        const minHeight = parseInt(this.options.minHeight);

        let { right, left, top, bottom } = this._container.getBoundingClientRect();

        right = Math.round(right);
        left = Math.round(left);
        top = Math.round(top);
        bottom = Math.round(bottom);

        const dx = clientX - startX;
        const dy = clientY - startY;

        if (horizontal === ResizeDirections.Right) {
            const width = startWidth + dx;

            if (
                (bindings && bindings.right) &&
                (Math.abs(bindings.right - clientX + offsetLeft) <= this.ignoreOffset)
            ) {
                this.width = bindings.right - left + offsetLeft;
            } else if (width <= minWidth) {
                this.width = minWidth;
            } else if (clientX >= parentCords.right) {
                this.width = parentCords.right - left;
            } else {
                this.width = width;
            }
        }

        if (horizontal === ResizeDirections.Left) {
            const width = startWidth - dx;

            if (
                (bindings && bindings.left) &&
                (Math.abs(bindings.left - clientX + offsetLeft) <= this.ignoreOffset)
            ) {
                this.width = right - bindings.left - offsetLeft;
                this.x = bindings.left + offsetLeft;
            } else if (width <= minWidth) {
                this.width = minWidth;
                this.x = right - minWidth;
            } else if (clientX <= parentCords.left) {
                this.x = parentCords.left;
                this.width = right - parentCords.left;
            } else {
                this.x = clientX;
                this.width = width;
            }
        }

        if (vertical === ResizeDirections.Down) {
            const height = startHeight + dy;

            if (
                (bindings && bindings.bottom) &&
                (Math.abs(bindings.bottom - clientY + offsetTop) <= this.ignoreOffset)
            ) {
                this.height = bindings.bottom - top + offsetTop;
            }
            else if (height <= minHeight) {
                this.height = minHeight;

            } else if (clientY >= parentCords.bottom) {
                this.height = parentCords.bottom - top;
            } else {
                this.height = height;
            }
        }

        if (vertical === ResizeDirections.Up) {
            const height = startHeight - dy;

            if (
                (bindings && bindings.top) &&
                (Math.abs(bindings.top - clientY + offsetTop) <= this.ignoreOffset)
            ) {
                this.height = bottom - bindings.top - offsetTop;
                this.y = bindings.top + offsetTop;
            } else if (height <= minHeight) {
                this.height = minHeight;
                this.y = bottom - minHeight;
            } else if (clientY <= parentCords.top) {
                this.y = parentCords.top;
                this.height = bottom - parentCords.top;
            } else {
                this.y = clientY;
                this.height = height;
            }
        }

        this.emit('resize', this);
    }

    _getShifts(point, state) {
        const { horizontal, vertical } = state;
        const target = this._container;
        const cords = target.getBoundingClientRect();

        const result = {
            shiftX: 0,
            shiftY: 0,
        }

        if (horizontal === ResizeDirections.Right) {
            result.shiftX = point.x - cords.right;
        }

        if (horizontal === ResizeDirections.Left) {
            result.shiftX = point.x - cords.left;
        }

        if (vertical === ResizeDirections.Down) {
            result.shiftY = point.y - cords.bottom;
        }

        if (vertical === ResizeDirections.Up) {
            result.shiftY = point.y - cords.top;
        }

        return result;
    }


    dragTest(target) {
        return target.classList.contains(BASE_WINDOW_CLASS) || target.classList.contains(this.options.draggableClass);
    }


    hitTest(point) {
        if (this.minimized)
            return;

        const x = point.x - this.globalOffset.left;
        const y = point.y - this.globalOffset.top;

        return (y >= this.y - ResizingSencetive)
            && (y <= this.y + this.height + ResizingSencetive)
            && (x >= this.x - ResizingSencetive)
            && (x <= this.x + this.width + ResizingSencetive);
    }

    hitRectTest(rect) {
        const x = point.x;
        const y = point.y;

        return (y >= this.y)
            && (y <= this.y + this.height)
            && (x >= this.x)
            && (x <= this.x + this.width);
    }

    /**
     * attaches window to a side of the screen
     * @param {('horizontal'|'vertical')} direction
     * @param {('left'|'right'|'top'|'bottom')} location
     */
    attachToScreen(direction, location) {
        this._attachedToScreen[direction] = location
    }

    /**
     * @param {Bounds} bounds
     * @param {(boolean|'horizontal'|'vertical')} keepInside
     */
    resizePlacement(bounds, keepInside) {
        this.bounds = bounds
        this.keepInside = keepInside
        let x = this.x
        let y = this.y
        x = this._attachedToScreen.horziontal === 'right' ? bounds.right - this.width : x
        x = this._attachedToScreen.horizontal === 'left' ? bounds.left : x
        y = this._attachedToScreen.vertical === 'bottom' ? bounds.bottom - this.height : y
        y = this._attachedToScreen.vertical === 'top' ? bounds.top : y

        this.move(x, y);
    }

    getRect() {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
        }
    }
}

Window.id = 0
