import { Injectable, Injector } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { Connection } from 'accounts-manager';
import { environment } from 'environment';
import { nanoid } from 'nanoid';
import { NotifierService } from 'notifier';
import {
  IMetric,
  MetricName,
  PerformanceMetricsService,
} from 'projects/performance-tools/src/lib/performance-metrics.service';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { IInstrument, IOrder, OrderAccount } from 'trading';

import {
  ILatencyMetrics,
  TradeLatencyMonitor,
} from '../../../../../src/app/services/trade-latency-monitor.service';
import { Id } from '../../../../communication/src/common/item';
import {
  AlertType,
  ConnectionId,
  IWebSocketStats,
  RITHMIC_INFRA_TYPE,
} from '../../../../communication/src/services/types';
import { MessageTypes } from '../../../../notification/src/lib/enums';
import { RealtimeType } from '../../../../real-trading/src/trading/repositories/realtime';
import { IConnection } from '../../../../trading/src/trading/models/connection';
import {
  OrderDuration,
  OrderSide,
  OrderType,
} from '../../../../trading/src/trading/models/order';
import { CommunicationConfig } from '../../http';
import { ConnectionWebSocketService } from '../connection.web-socket.service';
import { WEB_SOCKET_TYPE } from '../types';
import { rti } from './messages-js/otps_proto_pool';
import { RProtocolDataTransformer } from './rprotocol-data-transformer';
import { RProtocolEndpointMapper } from './rprotocol-endpoint-mapper';
import { RProtocolMessageTemplateNameEnum } from './rprotocol-message-template-name-enum';
import { RProtocolOrderPlantService } from './rprotocol-order-plant.service';
import { RProtocolTemplateRegistryService } from './rprotocol-template-registry.service';
import { RProtocolTickerPlantService } from './rprotocol-ticker-plant.service';
import { BracketType, OrderPlacement, UserType } from './rprotocol.model';

import RequestMarketDataUpdate = rti.RequestMarketDataUpdate;
import ResponseLogin = rti.ResponseLogin;

declare const crypto: any;

/**
 * @see request_new_order.proto
 */
const PriceType: { [key in OrderType]: number } = {
  [OrderType.Limit]: 1,
  [OrderType.Market]: 2,
  [OrderType.StopLimit]: 3,
  [OrderType.StopMarket]: 4,
};

/**
 * @see request_new_order.proto
 */
const TransactionType: { [key in OrderSide]: number } = {
  [OrderSide.Buy]: 1,
  [OrderSide.Sell]: 2,
};

const TRADING_GLOBALLY_DISABLED_ERROR: string =
  'Can not make new orders when trading is locked globally';

/**
 * `RProtocolConnectionWebSocketService` is an R Protocol WebSocket connection.
 */
@Injectable({
  providedIn: 'root',
})
export class RProtocolConnectionWebSocketService extends ConnectionWebSocketService {
  public static macAddresses: string[] = [];
  private _dataTransformer = new RProtocolDataTransformer();
  private _notifierService: NotifierService;
  private _rProtocolOrderPlantService: RProtocolOrderPlantService;
  private _rProtocolTickerPlantService: RProtocolTickerPlantService;
  private heartbeatIntervalId: number;
  private inFlightRequestIds: Set<string> = new Set<string>();
  private heartbeatInterval: number;
  private heartbeatLastPingTime: number;
  private metricsService: PerformanceMetricsService;
  private tradeLatencyMonitor: TradeLatencyMonitor;

  constructor(
    protected _injector: Injector,
    protected _config: CommunicationConfig,
    protected _templateRegistry: RProtocolTemplateRegistryService,
    private _infraType: RITHMIC_INFRA_TYPE,
  ) {
    super(_injector, _config, true);
    this.infraType = _infraType;

    this._rProtocolOrderPlantService = _injector.get(
      RProtocolOrderPlantService,
    );
    this._rProtocolTickerPlantService = _injector.get(
      RProtocolTickerPlantService,
    );
    this._notifierService = _injector.get(NotifierService);
    this.metricsService = _injector.get(PerformanceMetricsService);

    this.connection$.subscribe((value) => {
      if (!value) {
        this.loggedIn$.next(false);
      }
    });
    this.provisionMACAddresses();
    if (this.isTickerPlant()) {
      this.tradeLatencyMonitor = new TradeLatencyMonitor();
      this.tradeLatencyMonitor.disableWarmUp();
      this.metricsService.registerReporter(this);
    }
  }

  isOrderPlant(): boolean {
    return this.infraType === RITHMIC_INFRA_TYPE.ORDER_PLANT;
  }

  isTickerPlant(): boolean {
    return this.infraType === RITHMIC_INFRA_TYPE.TICKER_PLANT;
  }

  /**
   * @deprecated Please use `WebSocketRegistryService` instead.
   * @see {@link WebSocketRegistryService}
   */
  get(connection: IConnection): ConnectionWebSocketService {
    throw Error('DEPRECATED: use `WebSocketRegistryService` instead');
  }

  provisionMACAddresses() {
    if (RProtocolConnectionWebSocketService.macAddresses?.length > 0) {
      return;
    }
    const ipcBridge: any = (window as any).ipcBridge;
    // Available on Electron only
    if (ipcBridge) {
      ipcBridge
        .requestMACAddresses()
        .then((addresses?: string[]) => {
          if (!addresses?.length) {
            console.warn('No MAC Addresses provisioned');
            return;
          }
          console.log('Provisioned MAC Addresses:', addresses);
          RProtocolConnectionWebSocketService.macAddresses = addresses;
        })
        .catch((err) => {
          console.error('Failed to provision MAC Addresses', err);
        });
    } else {
      // console.warn('IPC Bridge not available, cannot provision MAC addresses');
    }
  }

  connect() {
    if (this.connection$.value) {
      return;
    }

    this._statistic.startTime = new Date();

    let url: string = RProtocolEndpointMapper.getEndpointForConnection(
      this.connection,
    );
    if (
      this.isTickerPlant() &&
      environment.rProtocolSimulator &&
      this.connection.useSimulator
    ) {
      url = environment.rProtocolSimulator;
    }
    this.log('Connecting to: ', url);

    this._websocket = new ReconnectingWebSocket(url, [], {
      minReconnectionDelay: 3000,
      // debug: true,
      maxRetries: 1,
    });
    this._websocket.binaryType = 'arraybuffer';
    this._addEventListeners();
    this._service.register(this.connection.id, this);
  }

  send(data: any = {}, connectionId: Id): void {
    if (data?.type === 'Id') {
      this.sendLoginRequest(this.connection as Connection);
      return;
    }
    if (data?.Type === 'subscribe' || data?.Type === 'unsubscribe') {
      const tickers = Array.isArray(data.Value) ? data.Value : [data.Value];
      const request =
        data.Type === 'subscribe'
          ? RequestMarketDataUpdate.Request.SUBSCRIBE
          : RequestMarketDataUpdate.Request.UNSUBSCRIBE;
      tickers.forEach((ticker) => {
        this.sendRequestMarketDataUpdate(ticker, request);
      });
    }
  }

  protected _handleMessage(event: MessageEvent) {
    if (!event?.data) return;

    this.measureIncomingMessagesRate();
    const eventData = event.data;

    if (eventData instanceof ArrayBuffer) {
      const message = this._templateRegistry.decodeMessage(eventData) as any;

      const msgType: string = message.constructor.name;
      if (!this._statistic.typology[msgType]) {
        this._statistic.typology[msgType] = 0;
      }
      this._statistic.typology[msgType]++;

      this.attachRequestDetails(message);
      const payload = this.transformData(message);

      if (payload) {
        this._handleMessagePayload(payload);
      }
      this._notifySubscribers(<protobuf.Message>message);
    } else {
      // Assume it's in base62 string format (debug mode)
      const message = this._templateRegistry.decodeMessageFromBase64(
        eventData,
      ) as any;
      const payload = this.transformData(message);
      this._notifySubscribers(<protobuf.Message>message);
      if (payload) {
        this._handleMessagePayload(payload);
      }
    }
  }

  private attachRequestDetails(message: any): void {
    if (!message.userMsg?.length) {
      return;
    }
    message.requestUid = message.userMsg[0];
  }

  private transformData(message: any): any {
    let payload = [];

    let isPrintableMessage = true;

    if (
      message.constructor.name ===
      RProtocolMessageTemplateNameEnum.ResponseLogin
    ) {
      const loginResponse = message as ResponseLogin;
      if (
        loginResponse.rpCode.length === 1 &&
        loginResponse.rpCode[0] === '0'
      ) {
        // Successfully logged in with R Protocol
        payload.push({
          type: 'Message',
          source: WEB_SOCKET_TYPE.RPROTOCOL,
          result: {
            value: 'Api-key accepted!',
            source: WEB_SOCKET_TYPE.RPROTOCOL,
          },
        });
        this.loggedIn$.next(true);
        if (loginResponse.heartbeatInterval > 0) {
          this.heartbeatInterval = loginResponse.heartbeatInterval;
          this.stopHeartbeat();
          this.startHeartBeat(this.heartbeatInterval);
        }
        if (this.isOrderPlant()) {
          this.sendRequestAccountList(loginResponse.fcmId, loginResponse.ibId);
        }
      } else {
        // Failed to log in with R Protocol
        const userFriendlyMessage = 'Login failed';
        let technicalError = userFriendlyMessage;
        if (loginResponse.rpCode[1]) {
          technicalError += ` (code: ${loginResponse.rpCode[0]}, reason: ${loginResponse.rpCode[1]})`;
        }
        payload.push({
          type: 'Connect',
          source: WEB_SOCKET_TYPE.RPROTOCOL,
          result: {
            connectionId: ConnectionId.MarketData,
            type: AlertType.ConnectionClosed,
            technicalError,
            message: userFriendlyMessage,
            source: WEB_SOCKET_TYPE.RPROTOCOL,
          },
        });
      }
    } else if (
      message.constructor.name ===
      RProtocolMessageTemplateNameEnum.ResponseAccountList
    ) {
      const accountInfo: rti.ResponseAccountList =
        message as rti.ResponseAccountList;
      if (accountInfo.fcmId) {
        this.requestSubscribeForOrderUpdates(
          accountInfo.accountId,
          accountInfo.fcmId,
          accountInfo.ibId,
        );
      }
      isPrintableMessage = true;
    } else if (
      message.constructor.name ===
      RProtocolMessageTemplateNameEnum.ResponseLogout
    ) {
      this.loggedIn$.next(false);
      payload.push({
        type: MessageTypes.CONNECT,
        source: WEB_SOCKET_TYPE.RPROTOCOL,
        result: {
          connectionId: ConnectionId.MarketData,
          type: AlertType.ConnectionClosed,
          source: WEB_SOCKET_TYPE.RPROTOCOL,
        },
      });
    } else if (
      message.constructor.name === RProtocolMessageTemplateNameEnum.BestBidOffer
    ) {
      const bbo: rti.BestBidOffer = message as rti.BestBidOffer;
      this.incrementStatsCounter(bbo.constructor.name, bbo.symbol);

      payload = this._dataTransformer.transformBestBidOffer(message);
      isPrintableMessage = false;
    } else if (
      message.constructor.name === RProtocolMessageTemplateNameEnum.OrderBook
    ) {
      const orderBook: rti.OrderBook = message as rti.OrderBook;
      this.incrementStatsCounter(orderBook.constructor.name, orderBook.symbol);

      payload = this._dataTransformer.transformOrderBook(message);
      isPrintableMessage = false;
    } else if (
      message.constructor.name === RProtocolMessageTemplateNameEnum.LastTrade
    ) {
      const lastTrade: rti.LastTrade = message as rti.LastTrade;
      this.incrementStatsCounter(lastTrade.constructor.name, lastTrade.symbol);

      payload = this._dataTransformer.transformLastTrade(message);
      if (
        this.tradeLatencyMonitor?.shouldSampleBasedOnTime() &&
        payload.length > 0
      ) {
        const _lastTrade = payload.find(
          (p) => p.type === RealtimeType.TradePrint,
        );
        if (_lastTrade?.result) {
          this.tradeLatencyMonitor.takeSampledMeasurement(_lastTrade?.result);
        }
      }
      isPrintableMessage = false;
    } else if (
      message.constructor.name ===
      RProtocolMessageTemplateNameEnum.ResponseMarketDataUpdate
    ) {
      payload.push({
        type: this._templateRegistry.getNameByTemplateId(message.templateId),
        source: WEB_SOCKET_TYPE.RPROTOCOL,
        message,
      });
    } else if (
      message.constructor.name ===
      RProtocolMessageTemplateNameEnum.EndOfDayPrices
    ) {
      payload = this._dataTransformer.transformEndOfDayPrices(message);
    } else if (
      message.constructor.name === RProtocolMessageTemplateNameEnum.Reject
    ) {
      payload.push({
        type: this._templateRegistry.getNameByTemplateId(message.templateId),
        source: WEB_SOCKET_TYPE.RPROTOCOL,
        message,
      });
      isPrintableMessage = true;
    } else if (
      [
        RProtocolMessageTemplateNameEnum.ExchangeOrderNotification,
        RProtocolMessageTemplateNameEnum.RithmicOrderNotification,
        RProtocolMessageTemplateNameEnum.ResponseShowOrderHistory,
      ].includes(message.constructor.name)
    ) {
      isPrintableMessage = true;
    } else if (
      message.constructor.name ===
      RProtocolMessageTemplateNameEnum.ResponseHeartbeat
    ) {
      // const pongTime: number = getTimestamp(message); // @todo add a new metric based on the time provided by the heartbeat response?
      const now: number = performance.now();
      const diff: number = now - this.heartbeatLastPingTime;
      const metricName: MetricName = this.isTickerPlant()
        ? MetricName.WebSocketTickerPlantLatency
        : MetricName.WebSocketOrderPlantLatency;
      this.metricsService.reportMetric(metricName, diff);
    } else {
      // TODO unexpected messaged type => send a warning to Sentry
      isPrintableMessage = true;
    }

    if (isPrintableMessage) {
      this.log('Received:', {
        message,
      });
    }

    return payload;
  }

  private sendRequest(templateType: any, requestData: any): string {
    // Electron runs on an old browser version, so the native Crypto API is not available, use a replacement for now
    // TODO remove `nanoid` after upgrading to a newer browser for Electron
    const requestUid: string = crypto?.randomUUID
      ? crypto.randomUUID()
      : nanoid();

    if (requestData.userMsg) {
      requestData.userMsg.unshift(requestUid);
    }

    const newBuffer = this._templateRegistry.prepareRequestBufferFromObject(
      templateType,
      requestData,
    );

    if (this.connected && this.isLoggedIn) {
      this._websocket.send(newBuffer);
      this.log('[AR] Sending:', {
        templateType,
        requestData,
      });
    } else {
      this._delayedAuthenticatedMessages.push(newBuffer);
      this.log(
        '[AR] Enqueued:',
        {
          templateType,
          requestData,
        },
        { connected: this.connected, isLoggedIn: this.isLoggedIn },
      );
    }

    return requestUid;
  }

  private sendNonAuthRequest(templateType: any, requestData: any): string {
    const requestUid: string = crypto?.randomUUID
      ? crypto.randomUUID()
      : nanoid();

    if (requestData.userMsg) {
      requestData.userMsg.unshift(requestUid);
    }

    const newBuffer = this._templateRegistry.prepareRequestBufferFromObject(
      templateType,
      requestData,
    );

    if (this.connected) {
      this._websocket.send(newBuffer);
      this.log('[NAR] Sending:', {
        templateType,
        requestData,
      });
    } else {
      this._delayedMessages.push(newBuffer);
      this.log('[NAR] Enqueued:', {
        templateType,
        requestData,
      });
    }

    return requestUid;
  }

  /**
   * Sends a unique request identified by the inflightRequestKey.
   * If a request with the same key is already in progress, it exits without sending a new request.
   * Upon receiving a response, it checks if it corresponds to the original request and cleans up the in-flight tracking.
   *
   * @param templateType - The type of the R Protocol message template to use for the request.
   * @param requestData - The data to be sent with the request.
   * @param inflightRequestKey - A unique key that identifies the in-flight request.
   * @param $response - A Subject that emits the response for the request.
   * @returns The unique identifier (UID) of the sent request, or undefined if the request was not sent.
   */
  public sendUniqueInflightRequest<T>(
    templateType: RProtocolMessageTemplateNameEnum,
    requestData: any,
    inflightRequestKey: string,
    $response: Subject<T>,
  ): string {
    if (this.inFlightRequestIds.has(inflightRequestKey)) {
      return;
    }

    this.inFlightRequestIds.add(inflightRequestKey);
    const requestUid: string = this.sendRequest(templateType, requestData);

    const sub: Subscription = $response.subscribe((response: T) => {
      if ((response as any)?.requestUid === requestUid) {
        this.inFlightRequestIds.delete(inflightRequestKey);
        sub?.unsubscribe();
      }
    });

    return requestUid;
  }

  private sendLoginRequest(connection: Connection) {
    const data = {
      appName: environment.rProtocol.appName,
      appVersion: environment.rProtocol.appVersion,
      password: connection.password,
      user: connection.username,
      infraType: this._infraType,
      systemName: connection.server,
      macAddr: RProtocolConnectionWebSocketService?.macAddresses || [],
      templateVersion: '5.27',
    };
    return this.sendNonAuthRequest(
      RProtocolMessageTemplateNameEnum.RequestLogin,
      data,
    );
  }

  initiateClosing(): void {
    this.sendLogoutRequest();
    this.stopHeartbeat();
  }

  private sendLogoutRequest() {
    const data = {
      userMsg: [],
      templateId: 12,
    };
    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestLogout, data);
  }

  private sendRequestMarketDataUpdate(
    ticker: any,
    request: RequestMarketDataUpdate.Request = RequestMarketDataUpdate.Request
      .SUBSCRIBE,
  ) {
    const updateBitsMask =
      // tslint:disable-next-line:no-bitwise
      RequestMarketDataUpdate.UpdateBits.LAST_TRADE |
      RequestMarketDataUpdate.UpdateBits.BBO |
      RequestMarketDataUpdate.UpdateBits.ORDER_BOOK |
      RequestMarketDataUpdate.UpdateBits.OPEN |
      RequestMarketDataUpdate.UpdateBits.OPENING_INDICATOR |
      RequestMarketDataUpdate.UpdateBits.HIGH_LOW |
      RequestMarketDataUpdate.UpdateBits.HIGH_BID_LOW_ASK |
      RequestMarketDataUpdate.UpdateBits.CLOSE |
      RequestMarketDataUpdate.UpdateBits.CLOSING_INDICATOR |
      RequestMarketDataUpdate.UpdateBits.MARKET_MODE |
      RequestMarketDataUpdate.UpdateBits.OPEN_INTEREST |
      RequestMarketDataUpdate.UpdateBits.MARGIN_RATE |
      RequestMarketDataUpdate.UpdateBits.HIGH_PRICE_LIMIT |
      RequestMarketDataUpdate.UpdateBits.LOW_PRICE_LIMIT |
      RequestMarketDataUpdate.UpdateBits.ADJUSTED_CLOSE;
    const data = {
      userMsg: [],
      exchange: ticker.tradingExchange ?? ticker.exchange,
      symbol: ticker.tradingSymbol ?? ticker.symbol,
      request,
      updateBits: updateBitsMask,
    };
    this.sendRequest(
      RProtocolMessageTemplateNameEnum.RequestMarketDataUpdate,
      data,
    );
  }

  public subscribeToSettlementPrice(instrument: IInstrument): string {
    return this.requestSettlementPrice(
      instrument,
      RequestMarketDataUpdate.Request.SUBSCRIBE,
    );
  }

  public unsubscribeToSettlementPrice(instrument: IInstrument): string {
    return this.requestSettlementPrice(
      instrument,
      RequestMarketDataUpdate.Request.UNSUBSCRIBE,
    );
  }

  public requestSettlementPrice(
    instrument: IInstrument,
    request: RequestMarketDataUpdate.Request = RequestMarketDataUpdate.Request
      .SUBSCRIBE,
  ): string {
    // tslint:disable:no-bitwise
    const updateBits: RequestMarketDataUpdate.UpdateBits =
      RequestMarketDataUpdate.UpdateBits.SETTLEMENT |
      RequestMarketDataUpdate.UpdateBits.PROJECTED_SETTLEMENT;
    // tslint:enable:no-bitwise
    const symbol: string = instrument.tradingSymbol ?? instrument.symbol;
    const requestStr: string =
      request === RequestMarketDataUpdate.Request.SUBSCRIBE
        ? 'SUBSCRIBE'
        : 'UNSUBSCRIBE';

    return this.sendRequest(
      RProtocolMessageTemplateNameEnum.RequestMarketDataUpdate,
      {
        userMsg: [JSON.stringify({ requestStr, symbol, updateBits })],
        exchange: instrument.tradingExchange ?? instrument.exchange,
        symbol,
        request,
        updateBits,
      },
    );
  }

  public subscribeToOrderBookSnapshot(instrument: IInstrument): string {
    return this.requestOrderBookSnapshot(
      instrument,
      RequestMarketDataUpdate.Request.SUBSCRIBE,
    );
  }

  public unsubscribeFromOrderBookSnapshot(instrument: IInstrument): string {
    return this.requestOrderBookSnapshot(
      instrument,
      RequestMarketDataUpdate.Request.UNSUBSCRIBE,
    );
  }

  public requestOrderBookSnapshot(
    instrument: IInstrument,
    request: RequestMarketDataUpdate.Request = RequestMarketDataUpdate.Request
      .SUBSCRIBE,
  ) {
    // tslint:disable:no-bitwise
    const updateBits: RequestMarketDataUpdate.UpdateBits =
      RequestMarketDataUpdate.UpdateBits.ORDER_BOOK;
    // tslint:enable:no-bitwise
    const symbol: string = instrument.tradingSymbol ?? instrument.symbol;
    const requestStr: string =
      request === RequestMarketDataUpdate.Request.SUBSCRIBE
        ? 'SUBSCRIBE'
        : 'UNSUBSCRIBE';
    const data = {
      userMsg: [JSON.stringify({ requestStr, symbol, updateBits })],
      exchange: instrument.tradingExchange ?? instrument.exchange,
      symbol,
      request,
      updateBits,
    };
    return this.sendRequest(
      RProtocolMessageTemplateNameEnum.RequestMarketDataUpdate,
      data,
    );
  }

  private stopHeartbeat(): void {
    clearInterval(this.heartbeatIntervalId);
  }

  private startHeartBeat(intervalSeconds: number): void {
    this.heartbeatIntervalId = setInterval(() => {
      this.heartbeatLastPingTime = performance.now();
      this.sendHeartbeat();
    }, intervalSeconds * 1000);
  }

  private sendHeartbeat(): void {
    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestHeartbeat, {
      userMsg: [],
    });
  }

  /**
   * @see request_show_orders.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestShowOrders(
    id: string,
    fcmId: string,
    ibId: string,
    userMsg?: string[],
  ): string {
    return this.sendUniqueInflightRequest(
      RProtocolMessageTemplateNameEnum.RequestShowOrders,
      {
        userMsg,
        fcmId,
        ibId,
        accountId: id,
      },
      `${RProtocolMessageTemplateNameEnum.RequestShowOrders}-${id}-${fcmId}-${ibId}`,
      this._rProtocolOrderPlantService.$responseShowOrders,
    );
  }

  /**
   * @see request_cancel_order.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestCancelOrder(
    order: IOrder,
    isTradingGloballyEnabled: boolean,
    userMsg?: string,
  ): void {
    if (!isTradingGloballyEnabled) {
      this._notifierService.showError(TRADING_GLOBALLY_DISABLED_ERROR);
      throw new Error(TRADING_GLOBALLY_DISABLED_ERROR);
    }

    console.log('requestCancelOrder order', JSON.parse(JSON.stringify(order)));

    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestCancelOrder, {
      manualOrAuto: OrderPlacement.MANUAL,
      accountId: order.accountId || order.account.id,
      fcmId: order.account.fcmId,
      ibId: order.account.ibId,
      basketId: order.id,
      userMsg: userMsg ? [userMsg] : null,
    });
  }

  /**
   * @see request_trade_routes.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestTradeRoutes(subscribeForUpdates, userMsg?: string): void {
    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestTradeRoutes, {
      subscribeForUpdates,
      userMsg: [userMsg],
    });
  }

  /**
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   * @see {link https://tradrr.atlassian.net/wiki/spaces/TRADRR/pages/60489729/R+Protocol+-+Order+Plant+-+Requests+Responses+and+Relationships#Order-Lifecycle} R Protocol - Order Plant - Requests, Responses and Relationships
   */
  public requestShowOrderHistory({
    id,
    fcmId,
    ibId,
    basketId,
    userMsg,
  }: Pick<OrderAccount, 'id' | 'fcmId' | 'ibId'> &
    Pick<rti.ExchangeOrderNotification, 'basketId'> &
    Pick<rti.RequestShowOrderHistory, 'userMsg'>): string {
    return this.sendUniqueInflightRequest(
      RProtocolMessageTemplateNameEnum.RequestShowOrderHistory,
      {
        accountId: id,
        fcmId,
        ibId,
        basketId,
        userMsg,
      },
      `${RProtocolMessageTemplateNameEnum.RequestShowOrderHistory}-${id}-${fcmId}-${ibId}-${basketId}`,
      this._rProtocolOrderPlantService.$responseShowOrderHistory,
    );
  }

  /**
   * @description Searches for information about symbols such as NQM4, ESM4 etc.
   * @param {string} symbol - Stock symbol For example: NQM4, ESM4.
   * @see request_search_symbols.proto
   * @see R Protocol API 1.2 Templates Specific to Market Data Infrastructure
   * @see {link https://www.investopedia.com/terms/s/stocksymbol.asp Stock Symbol (Ticker Symbol): Abbreviation for a Company's Stock}
   */
  public requestSearchSymbol(symbol: string): void {
    if (
      this._rProtocolTickerPlantService.$currentlyFetchedSymbols
        .getValue()
        .includes(symbol)
    ) {
      return;
    }

    this._rProtocolTickerPlantService.$requestSearchSymbols.next(symbol);
    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestSearchSymbols, {
      pattern: rti.RequestSearchSymbols.Pattern.EQUALS,
      instrumentType: rti.RequestSearchSymbols.InstrumentType.FUTURE,
      searchText: symbol,
    });
  }

  /**
   * @see request_modify_order.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestModifyOrder(
    order: IOrder,
    isTradingGloballyEnabled: boolean,
    userMsg?: string,
  ): void {
    if (!isTradingGloballyEnabled) {
      this._notifierService.showError(TRADING_GLOBALLY_DISABLED_ERROR);
      throw new Error(TRADING_GLOBALLY_DISABLED_ERROR);
    }

    const modifiedOrder: Partial<IOrder> & {
      fcmId: string;
      ibId: string;
      basketId: Id;
      priceType: number;
      manualOrAuto: OrderPlacement;
      userMsg?: [string];
    } = {
      fcmId: order?.account?.fcmId,
      ibId: order.account.ibId,
      accountId: order.account.id,
      basketId: order.id,
      symbol: order.symbol,
      exchange: order.exchange,
      quantity: order.quantity,
      price: order.price,
      triggerPrice: order.triggerPrice,
      duration: OrderDuration[order.duration.toUpperCase()],
      priceType: PriceType[order.type],
      manualOrAuto: OrderPlacement.MANUAL,
      userMsg: userMsg ? [userMsg] : null,
    };

    if (order.type === OrderType.StopMarket) {
      modifiedOrder.triggerPrice = order.price;
    }

    if (userMsg) {
      modifiedOrder.userMsg = [userMsg];
    }

    this.sendRequest(
      RProtocolMessageTemplateNameEnum.RequestModifyOrder,
      modifiedOrder,
    );
  }

  /**
   * @see request_exit_position.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestExitPosition(
    instrument: IInstrument,
    account: OrderAccount,
    userMsg: string,
  ): void {
    const { fcmId, ibId } = account;
    const { exchange, tradingSymbol } = instrument;

    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestExitPosition, {
      accountId: account.id,
      userMsg: [userMsg],
      fcmId,
      ibId,
      symbol: tradingSymbol,
      exchange,
      manualOrAuto: OrderPlacement.MANUAL,
    });
  }

  /**
   * @see request_new_order.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestNewOrder(
    newOrder: IOrder,
    isTradingGloballyEnabled: boolean,
  ): void {
    if (!isTradingGloballyEnabled) {
      this._notifierService.showError(TRADING_GLOBALLY_DISABLED_ERROR);
      throw new Error(TRADING_GLOBALLY_DISABLED_ERROR);
    }

    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestNewOrder, {
      fcmId: newOrder?.account?.fcmId,
      ibId: newOrder.account.ibId,
      accountId: newOrder.account.id,
      symbol: newOrder.symbol,
      exchange: newOrder.exchange,
      quantity: newOrder.quantity,
      price: newOrder.price,
      triggerPrice: newOrder.triggerPrice,
      transactionType: TransactionType[newOrder.side],
      duration: OrderDuration[newOrder.duration.toUpperCase()],
      priceType: PriceType[newOrder.type],
      tradeRoute: this._rProtocolOrderPlantService.getTradeRouteByExchange(
        newOrder.exchange,
      ),
      manualOrAuto: OrderPlacement.MANUAL,
    });
  }

  /**
   * @see request_bracket_order.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   */
  public requestBracketOrder(
    newBracketOrder: IOrder & Partial<{ stopLoss; takeProfit }>,
    isTradingGloballyEnabled: boolean,
    userMsg?: string,
  ): void {
    let bracketType: BracketType = BracketType.TARGET_AND_STOP;
    let stopTicks: number;
    let stopQuantity: number;
    let targetTicks: number;
    let targetQuantity: number;
    let triggerPrice: number;

    if (!isTradingGloballyEnabled) {
      this._notifierService.showError(TRADING_GLOBALLY_DISABLED_ERROR);
      throw new Error(TRADING_GLOBALLY_DISABLED_ERROR);
    }

    switch (true) {
      case !newBracketOrder.stopLoss?.stopLoss:
        bracketType = BracketType.TARGET_ONLY;
        break;
      case !newBracketOrder.takeProfit?.takeProfit:
        bracketType = BracketType.STOP_ONLY;
        break;
    }

    if (
      newBracketOrder.stopLoss?.stopLoss &&
      newBracketOrder.stopLoss.unit === 'ticks'
    ) {
      stopTicks = newBracketOrder.stopLoss.ticks;
      stopQuantity = newBracketOrder.quantity;
    }

    if (
      newBracketOrder.takeProfit?.takeProfit &&
      newBracketOrder.takeProfit.unit === 'ticks'
    ) {
      targetTicks = newBracketOrder.takeProfit.ticks;
      targetQuantity = newBracketOrder.quantity;
    }

    if (newBracketOrder.type === OrderType.StopMarket) {
      triggerPrice = newBracketOrder.price;
    }

    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestBracketOrder, {
      fcmId: newBracketOrder?.account?.fcmId,
      ibId: newBracketOrder.account.ibId,
      accountId: newBracketOrder.account.id,
      symbol: newBracketOrder.symbol,
      exchange: newBracketOrder.exchange,
      quantity: newBracketOrder.quantity,
      price: newBracketOrder.price,
      transactionType: TransactionType[newBracketOrder.side],
      duration: OrderDuration[newBracketOrder.duration.toUpperCase()],
      priceType: PriceType[newBracketOrder.type],
      tradeRoute: this._rProtocolOrderPlantService.getTradeRouteByExchange(
        newBracketOrder.exchange,
      ),
      manualOrAuto: OrderPlacement.MANUAL,
      userType: UserType.USER_TYPE_TRADER,
      bracketType,
      stopTicks,
      stopQuantity,
      targetTicks,
      targetQuantity,
      triggerPrice,
      userMsg: userMsg ? [userMsg] : null,
    });
  }

  /**
   * @see request_oco_order.proto
   * @see R Protocol API 1.3 Templates Specific to Order Plant Infrastructure
   * @see {link https://www.investopedia.com/terms/o/oco.asp} One-Cancels-the-Other (OCO) Order: Definition and Use in Trading
   */
  public requestOcoOrder(
    newOcoOrders: IOrder[],
    isTradingGloballyEnabled: boolean,
  ): void {
    if (!isTradingGloballyEnabled) {
      this._notifierService.showError(TRADING_GLOBALLY_DISABLED_ERROR);
      throw new Error(TRADING_GLOBALLY_DISABLED_ERROR);
    }

    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestOCOOrder, {
      fcmId: newOcoOrders[0]?.account?.fcmId,
      ibId: newOcoOrders[0].account.ibId,
      accountId: newOcoOrders[0].account.id,
      symbol: [newOcoOrders[0].symbol, newOcoOrders[1].symbol],
      exchange: [newOcoOrders[0].exchange, newOcoOrders[1].exchange],
      quantity: [newOcoOrders[0].quantity, newOcoOrders[1].quantity],
      price: [newOcoOrders[0].price, newOcoOrders[1].price],
      triggerPrice: [
        newOcoOrders[0].triggerPrice,
        newOcoOrders[1].triggerPrice,
      ],
      transactionType: [
        TransactionType[newOcoOrders[0].side],
        TransactionType[newOcoOrders[1].side],
      ],
      duration: [
        OrderDuration[newOcoOrders[0].duration.toUpperCase()],
        OrderDuration[newOcoOrders[1].duration.toUpperCase()],
      ],
      priceType: [
        PriceType[newOcoOrders[0].type],
        PriceType[newOcoOrders[1].type],
      ],
      tradeRoute: [
        this._rProtocolOrderPlantService.getTradeRouteByExchange(
          newOcoOrders[0].exchange,
        ),
        this._rProtocolOrderPlantService.getTradeRouteByExchange(
          newOcoOrders[1].exchange,
        ),
      ],
      manualOrAuto: [OrderPlacement.MANUAL, OrderPlacement.MANUAL],
    });
  }

  sendRequestAccountList(fcmId: string, ibId: string, userMsg?: string[]) {
    this.sendRequest(RProtocolMessageTemplateNameEnum.RequestAccountList, {
      fcmId,
      ibId,
      userMsg,
      userType: rti.RequestAccountList.UserType.USER_TYPE_TRADER,
    });
  }

  requestSubscribeForOrderUpdates(
    id: string,
    fcmId: string,
    ibId: string,
    userMsg?: string[],
  ): void {
    this.sendRequest(
      RProtocolMessageTemplateNameEnum.RequestSubscribeForOrderUpdates,
      {
        accountId: id,
        fcmId,
        ibId,
        userMsg,
      },
    );
  }

  private _notifySubscribers(message: protobuf.Message): void {
    const messageName: RProtocolMessageTemplateNameEnum = <
      RProtocolMessageTemplateNameEnum
    >message.constructor.name;

    switch (true) {
      case RProtocolMessageTemplateNameEnum.RequestShowOrders === messageName:
        this._rProtocolOrderPlantService.$requestShowOrders.next();
        break;
      case RProtocolMessageTemplateNameEnum.ResponseShowOrders === messageName:
        this._rProtocolOrderPlantService.$responseShowOrders.next(
          <rti.ResponseShowOrders>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ExchangeOrderNotification ===
        messageName:
        this._rProtocolOrderPlantService.$exchangeOrderNotification.next(
          <rti.ExchangeOrderNotification>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseSearchSymbols ===
        messageName:
        this._rProtocolTickerPlantService.$responseSearchSymbols.next(
          <rti.ResponseSearchSymbols>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.RithmicOrderNotification ===
        messageName:
        this._rProtocolOrderPlantService.$rithmicOrderNotification.next(
          <rti.RithmicOrderNotification>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseShowOrderHistory ===
        messageName:
        this._rProtocolOrderPlantService.$responseShowOrderHistory.next(
          <rti.ResponseShowOrderHistory>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseTradeRoutes === messageName:
        this._rProtocolOrderPlantService.$responseTradeRoutes.next(
          <rti.ResponseTradeRoutes>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseExitPosition ===
        messageName:
        this._rProtocolOrderPlantService.$responseExitPosition.next(
          <rti.ResponseExitPosition>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseBracketOrder ===
        messageName:
        this._rProtocolOrderPlantService.$responseBracketOrder.next(
          <rti.ResponseBracketOrder>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseModifyOrder === messageName:
        this._rProtocolOrderPlantService.$responseModifyOrder.next(
          <rti.ResponseModifyOrder>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseCancelOrder === messageName:
        this._rProtocolOrderPlantService.$responseCancelOrder.next(
          <rti.ResponseCancelOrder>(<unknown>message),
        );
        break;
      case RProtocolMessageTemplateNameEnum.ResponseOCOOrder === messageName:
        console.log(message);
        break;
    }
  }

  collectMetrics(): IMetric[] {
    const metrics: IMetric[] = [];

    const latencyMetrics: ILatencyMetrics =
      this.tradeLatencyMonitor.collectLatencyMetrics();
    if (latencyMetrics.latency) {
      metrics.push({
        name: MetricName.TradeLatency,
        value: latencyMetrics.latency,
      });
    }
    if (latencyMetrics.averageLatency) {
      metrics.push({
        name: MetricName.AverageTradeLatency,
        value: latencyMetrics.averageLatency,
      });
    }

    if (!this.isTickerPlant()) {
      return metrics;
    }

    const webSocketMetrics: IWebSocketStats = this.getStats();
    if (webSocketMetrics.averageMessagesValue) {
      metrics.push({
        name: MetricName.AverageMessagesPerSec,
        value: webSocketMetrics.averageMessagesValue,
      });
    }
    if (webSocketMetrics.peakMessagesPerSec) {
      metrics.push({
        name: MetricName.PeakMessagesPerSec,
        value: webSocketMetrics.peakMessagesPerSec,
      });
    }
    if (webSocketMetrics.messagesPerSec) {
      metrics.push({
        name: MetricName.MessagesPerSec,
        value: webSocketMetrics.messagesPerSec,
      });
    }

    return metrics;
  }

  resetMetrics(): void {
    super.resetMetrics();
    this.tradeLatencyMonitor.reset();
  }

  destroy(connection: IConnection, code: number = 1000, reason?: string): void {
    super.destroy(connection, code, reason);
    this.metricsService.unregisterReporter(this);
  }
}
