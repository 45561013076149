import { Injectable, Type } from '@angular/core';

import { DataLevel, IInstrument, IQuote } from 'trading';

import { IFeedRelayConfig } from './feed-relay.service';
import { RealFeed, WSMessageTypes } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealLevel1DataFeed extends RealFeed<IQuote, IInstrument> {
  type = RealtimeType.Quote;

  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;

  get useFeedRelay(): boolean {
    return true;
  }
  get provideFeedRelayConfig(): IFeedRelayConfig {
    return {
      flushBufferInterval: 500,
    };
  }

  protected _filter(item: IQuote): boolean {
    return item.level === DataLevel.Level1;
  }
}
