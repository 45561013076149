import { Injectable } from '@angular/core';

import {
  CommunicationConfig,
  ICommunicationConfig,
  ICommunicationHttpConfig,
  ICommunicationWsConfig,
  IIdentityConfig,
} from 'communication';
import { Config } from 'config';

@Injectable({
  providedIn: 'root',
})
export class AppConfig extends Config implements CommunicationConfig {
  http: ICommunicationHttpConfig;
  ws: ICommunicationWsConfig;
  setting: ICommunicationHttpConfig;
  rithmic: ICommunicationConfig;
  zoneDecoder: ICommunicationConfig;
  identity: IIdentityConfig;
}

export interface IAppVersion {
  dirty?: boolean;
  raw?: string;
  hash?: string;
  distance?: string;
  tag?: string;
  semver?: string;
  suffix?: string;
  semverString?: string;
  version?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppVersion {
  dirty?: boolean;
  raw?: string;
  hash?: string;
  distance?: string;
  tag?: string;
  semver?: string;
  suffix?: string;
  semverString?: string;
  version?: string;
}

export interface ISentryConfig {
  enabled?: boolean;
  dsn?: string;
  tracePropagationTargets?: string[];
  tracesSampleRate?: number;
  replaysSessionSampleRate?: number;
  replaysOnErrorSampleRate?: number;
}

export interface IEnvironment {
  appEnv: string;
  production: boolean;
  isDev: boolean;
  config: string;
  scxPath: string;
  stockChartXVersion: string;
  rProtocolSimulator: string;
  rProtocol: {
    appName: string;
    appVersion: string;
  };
  application: {
    angular?: string;
    name: string;
    bootstrap?: string;
    fontawesome?: string;
  };
  instrument: {
    symbol: string;
    productCode: string;
    instrumentTimePeriod: string;
    tradingExchange: string;
    precision: number;
    description: string;
    exchange: string;
    contractSize: number;
    company: string;
    id: string;
    tradingSymbol: string;
    tickSize: number;
  };
  sentry?: {
    tracePropagationTargets?: string[];
    tracesSampleRate?: number;
    replaysSessionSampleRate?: number;
    replaysOnErrorSampleRate?: number;
    enabled?: boolean;
    dsn?: string;
  };
  isPerformanceInfoEnabled: boolean;
}
