import { Injector } from '@angular/core';

import { untilDestroyed } from '@ngneat/until-destroy';
import { IBaseItem, Id, IPaginationResponse, Repository } from 'communication';
import { NotifierService } from 'notifier';
import { filterByAccountAndInstrument } from 'real-trading';
import { Feed, IInstrument } from 'trading';

import { ChartComponent } from '../chart.component';
import { IChart } from '../models';

export abstract class ChartObjects<
  T extends IBaseItem & { instrument?: IInstrument },
> {
  protected _instance: ChartComponent;
  protected _repository: Repository<T>;
  protected _notifier: NotifierService;
  protected _markersMap: any = {};
  protected _dataFeed: Feed<T>;
  private unsubscribeFn: () => void;
  items: T[] = [];

  protected get _chart(): IChart {
    return this._instance.chart;
  }

  protected get _injector(): Injector {
    return this._instance.injector;
  }

  protected get _params(): any {
    return {
      accountId: this._instance.accountId,
    };
  }

  constructor(instance: any) {
    this._instance = instance;
    this._notifier = this._injector.get(NotifierService);
  }

  init() {
    this.unsubscribeFn = this._dataFeed.on(
      filterByAccountAndInstrument(this._instance, (item: any) =>
        this.handle(item),
      ),
    );
  }

  handle(model: T) {
    const instrument = this._instance?.instrument;
    const symbol =
      instrument?.tradingSymbol == null
        ? instrument?.symbol
        : instrument?.tradingSymbol;

    if (
      instrument == null ||
      (model?.instrument?.symbol !== symbol &&
        model?.instrument?.tradingSymbol !== symbol)
    ) {
      return;
    }

    if (!this._markersMap[model.id]) {
      const marker = this.createMarker(model);

      if (marker == null) return;

      if (!this.items.some((item) => item.id === model.id)) {
        this.items = [...this.items, model];
      }

      marker.visible = this.shouldBarBeVisible(model);
      marker.chartPanel = this._chart.mainPanel;
      this._chart.mainPanel.addObjects(marker);

      this._markersMap[model.id] = marker;
    } else {
      const marker = this._markersMap[model.id];
      let order = this._map(model);
      marker.order = order;
      const index = this.items.findIndex((item) => item.id === model.id);

      if (index !== -1) {
        this.items[index] = model;
      }

      marker.update(false);
    }

    if (!this._isValid(model)) {
      this.delete(model.id);
      this._updateAllMarkersVisibility();
    }
  }
  protected _updateAllMarkersVisibility(): void {
    // do nothing
  }

  shouldBarBeVisible(item?: any): boolean {
    return this._instance.enableOrderForm;
  }

  abstract createMarker(model);

  refresh() {
    this._deleteItems();
    this._loadItems();
  }

  destroy() {
    if (this.unsubscribeFn) this.unsubscribeFn();
  }

  protected _loadItems() {
    if (!this._instance.accountId) {
      return;
    }

    this._repository
      .getItems(this._params)
      .pipe(untilDestroyed(this._instance))
      .subscribe((res: IPaginationResponse<T>) => {
        this.items = res.data;
        res.data.forEach((item) => {
          this.handle(item);
        });
        this._onDataLoaded();
      });
  }

  protected _onDataLoaded() {}

  protected _deleteItems() {
    Object.keys(this._markersMap).forEach((key) => {
      this.delete(key);
    });
    this.items = [];
  }

  delete(id: Id) {
    this._markersMap[id]?.remove();
    this.items = this.items.filter((item) => item.id !== id);
    delete this._markersMap[id];
  }

  protected _create(item: T, fn: (item: any) => {}) {
    if (!this._isValid(item)) {
      return;
    }

    const bar = fn(this._map(item));

    this._chart.chartPanels[0].addObjects(bar);

    this._markersMap[item.id] = bar;
  }

  protected _isValid(item: T): boolean {
    return true;
  }

  protected _map(item: T): any {
    return item;
  }
}
