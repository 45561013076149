export function addInstrumentId(item: any) {
  const symbol = item.tradingSymbol == null ? item.symbol : item.tradingSymbol;
  const exchange =
    item.tradingExchange == null ? item.tradingExchange : item.exchange;
  item.id = `${symbol}.${exchange}`;
  return item;
}

export function getTimestamp(obj: any): number {
  return obj.ssboe * 1000 + obj.usecs / 1000;
}
