import { IEnvironment } from '../app/app.config';
import { VERSION } from './version';

export const environment: IEnvironment = {
  appEnv: 'unknown',
  production: false,
  isDev: true,
  config: 'config/config.json',
  scxPath: './assets/StockChartX/scripts/develop/',
  stockChartXVersion: '2.22.26-6f569efb',
  rProtocolSimulator: '',
  rProtocol: {
    appName: 'Tradrr',
    appVersion: VERSION.version,
  },
  application: {
    name: 'Tradrr',
  },
  instrument: {
    id: 'ESU4.CME',
    description: 'Front Month for ES - ESU4.CME',
    exchange: 'CME',
    tickSize: 0.25,
    precision: 2,
    instrumentTimePeriod: 'Sep24',
    contractSize: 50,
    productCode: 'ES',
    symbol: 'ES',
    tradingSymbol: 'ESU4',
    tradingExchange: 'CME',
    company: '',
  },
  sentry: {
    enabled: true,
    dsn: 'https://1f6e6121dece1752a684de71c3a23816@o4506299804876800.ingest.sentry.io/4506299806056448',
    tracePropagationTargets: [
      'rc2.tradrr.dev',
      'userdata-dv2.tradrr.dev',
      'datafeed-dv2.tradrr.dev',
      'zonedecoder-dv2.tradrr.dev',
      'auth-dv2.tradrr.dev',
      'notification-dv2.tradrr.dev',
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  },
  isPerformanceInfoEnabled: false,
};
