import { Injectable } from '@angular/core';

import { WEB_SOCKET_TYPE } from 'communication';

import { AccountAutoLiquidateInfo } from '../../../../trading';
import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealAccountAutoLiquidateInfoFeed extends RealFeed<AccountAutoLiquidateInfo> {
  type = RealtimeType.AccountAutoLiquidateInfo;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RAPI;
  }

  get useFeedRelay(): boolean {
    return true;
  }

  protected _map(data: any): AccountAutoLiquidateInfo {
    if (Array.isArray(data)) {
      throw new Error('TODO implement this');
    }
    return this._mapResponseItem(data);
  }

  protected _mapResponseItem(item: any): AccountAutoLiquidateInfo {
    return {
      account: item.account,
      currentValue: item.currentValue,
      time: item.time,
    } as AccountAutoLiquidateInfo;
  }
}
