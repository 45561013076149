export class Counter<T> {
  private counters: Map<T, number>;
  private intervalId: number | null;
  private name: string;

  constructor(name: string = 'Unnamed Counter') {
    this.counters = new Map<T, number>();
    this.intervalId = null;
    this.name = name;
  }

  // Method to increment the count of a specific item
  incrementCount(item: T, incrementValue: number = 1): void {
    const currentCount = this.getCount(item);
    this.counters.set(item, currentCount + incrementValue);
  }

  setCount(item: T, countValue: number = 1): void {
    this.counters.set(item, countValue);
  }

  // Method to get the count of a specific item
  getCount(item: T): number {
    return this.counters.get(item) || 0;
  }

  // Method to display the statistics of all counters
  displayStats(): void {
    if (!this.counters.size) {
      return;
    }
    // Sorts `this.counters` so that the most popular items are first
    const sortedCounters: Map<T, number> = new Map(
      Array.from(this.counters.entries()).sort(
        (a: [T, number], b: [T, number]): number => b[1] - a[1],
      ),
    );

    console.log(this.name, sortedCounters);
  }

  // Method to reset the count of a specific item
  resetCount(item: T): void {
    this.counters.set(item, 0);
  }

  // Method to reset all counts
  resetAllCounts(): void {
    this.counters.forEach((_, item) => {
      this.counters.set(item, 0);
    });
  }

  // Method to start displaying stats every x seconds
  startDisplayingStats(intervalSeconds: number): void {
    if (this.intervalId !== null) {
      return;
    }
    this.intervalId = setInterval(
      () => this.displayStats(),
      intervalSeconds * 1000,
    );
  }

  // Method to stop displaying stats
  stopDisplayingStats(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
