import { Injectable } from '@angular/core';

import { WEB_SOCKET_TYPE } from 'communication';
import { IOrder, IPosition, PositionStatus } from 'trading';

import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealPositionsFeed extends RealFeed<IOrder> {
  type = RealtimeType.Position;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RAPI;
  }

  get useFeedRelay(): boolean {
    return true;
  }

  protected _filter(item: IOrder): boolean {
    // TODO: remove this filter after refactoring Positions component to support account-level aggregated PL, F/PL, etc.
    // from the data feed instead of recalculating it client-side

    // Filters out account-level positions, as Positions component doesn't support them
    // It only supports instrument-level positions (ie. feed messages with instrument info)
    if (!item?.instrument) return false;
    return super._filter(item);
  }

  protected _map(data: any): IPosition {
    if (Array.isArray(data)) {
      throw new Error('TODO implement this');
    }
    return this._mapResponseItem(data);
  }

  protected _mapResponseItem(item: any): IPosition {
    return {
      accountId: item.account.id,
      connectionId: item.connectionId,
      id: item.instrument
        ? `${item?.instrument?.id}.${item.account.id}`
        : item.account.id,
      price: item.averageFillPrice,
      size: item.volume,
      realized: item.realisedPL,
      unrealized: item.unrealisedPL,
      buyVolume: item.buyVolume,
      sellVolume: item.sellVolume,
      total: item.realisedPL + item.unrealisedPL,
      side: item.type,
      status: item.volume > 0 ? PositionStatus.Open : PositionStatus.Close,
      instrument: item.instrument,
      account: item.account,
      accountBalance: item.accountBalance,
    } as IPosition;
  }

  // merge(oldItem: IPosition, newItem: IPosition): IPosition {
  //   const realized = oldItem.realized + (newItem.side === Side.Long ? newItem.size : -newItem.size);
  //   const size = Math.abs(realized);
  //   const total = size ? oldItem.price * oldItem.size + newItem.price * newItem.size : 0;
  //   const price = total / (oldItem.size + newItem.size);

  //   const side = (() => {
  //     if (realized > 0) return Side.Long;
  //     if (realized < 0) return Side.Short;
  //     return Side.Closed;
  //   })();

  //   return { ...newItem, price, size, realized, total, side };
  // }
}
