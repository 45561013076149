  <div
    class="d-flex perf-metric"
    [ngClass]="colorByThreshold(tradeLatency)"
    title="Trade latency - Time difference between trade execution at the exchange and the app's receipt and processing of the trade"
  >
    <i
      class="icon-clock"
    ></i>
    TL
    <span title="Trade latency - instantaneous" *ngIf="tradeLatency; else valueNotAvailable"
          class="perf-value">{{ formatNumber(tradeLatency) }}</span>
    ·
    <span title="Trade latency - average" *ngIf="averageTradeLatency; else valueNotAvailable"
          [ngClass]="colorByThreshold(averageTradeLatency)"
          class="perf-value">{{ formatNumber(averageTradeLatency) }}</span>
    ms
  </div>

  <div class="vertical-divider"></div>

  <div
    class="d-flex perf-metric"
    [ngClass]="colorByThreshold(domTradeLatency)"
    title="DOM Trade E2E latency - Time difference between trade execution at the exchange and the app's rendering of the trade in the DOM"
  >
    <i
      class="icon-clock"
    ></i>
    DOMTL
    <span title="DOM Trade latency - instantaneous" *ngIf="domTradeLatency; else valueNotAvailable"
          class="perf-value">{{ formatNumber(domTradeLatency) }}</span>
    ·
    <span title="DOM Trade latency - average" *ngIf="domAverageTradeLatency; else valueNotAvailable"
          [ngClass]="colorByThreshold(domAverageTradeLatency)"
          class="perf-value">{{ formatNumber(domAverageTradeLatency) }}</span>
    ms
  </div>

  <div class="vertical-divider"></div>

  <div
    class="d-flex  perf-metric"
    [ngClass]="colorByThreshold(messagesPerSec,[0, 2000, 6000])"
    title="Incoming Messages rate (busiest web socket) "
  >
    <i
      class="icon-tool-circle"
    ></i>
    IMws
    <span title="Incoming Messages - instantaneous rate" *ngIf="messagesPerSec; else valueNotAvailable"
          class="perf-value medium">{{ formatNumber(messagesPerSec) }}</span>
    ·
    <span title="Incoming Messages - average rate" *ngIf="averageMessagesPerSec; else valueNotAvailable"
          [ngClass]="colorByThreshold(averageMessagesPerSec,[0, 2000, 6000])"
          class="perf-value medium">{{ formatNumber(averageMessagesPerSec) }}</span>
    ·
    <span title="Incoming Messages - peak rate" *ngIf="peakMessagesPerSec; else valueNotAvailable"
          [ngClass]="colorByThreshold(peakMessagesPerSec,[0, 2000, 6000])"
          class="perf-value medium">{{ formatNumber(peakMessagesPerSec) }}</span>
    msg/s
  </div>

  <div class="vertical-divider"></div>

  <div
    class="d-flex  perf-metric"
    [ngClass]="colorByThreshold(webSocketTickerPlantLatency)"
    title="Ticker Plant web socket latency"
  >
    <i
      class="icon-clock"
    ></i>
    TPws
    <span *ngIf="webSocketTickerPlantLatency; else valueNotAvailable" class="perf-value">{{
        formatNumber(webSocketTickerPlantLatency)
      }}</span>
    ms
  </div>

  <div class="vertical-divider"></div>

  <div
    class="d-flex  perf-metric"
    [ngClass]="colorByThreshold(webSocketOrderPlantLatency)"
    title="Order Plant web socket latency"
  >
    <i
      class="icon-clock"
    ></i>
    OPws
    <span *ngIf="webSocketOrderPlantLatency; else valueNotAvailable" class="perf-value">{{
        formatNumber(webSocketOrderPlantLatency)
      }}</span>
    ms
  </div>

  <div class="vertical-divider"></div>

  <div
    class="d-flex flex-column  button-container"
  >
    <div>
      <button nz-button nzType="default" size="small" nzShape="circle" class="icon-button"
      (click)="resetMetrics()"
      >
        <i nz-icon
           class="icon-reset"
        ></i>
      </button>
    </div>
    <div>
      <button nz-button nzType="default" size="small" nzShape="circle" class="icon-button"
              (click)="copyMetricsToClipboard()">
        <i nz-icon
           class="icon-save"
        ></i>
      </button>
    </div>
  </div>

<ng-template #valueNotAvailable>
  <span class="perf-value">&hellip;</span>
</ng-template>
