import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DebugFlagService {
  private debugModeSecretCode: string = 'debug';
  private debugModeInputCode: string = '';
  private debugModeSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  debugMode$: Observable<boolean> = this.debugModeSubject.asObservable();

  toggleDebugMode(): void {
    this.debugModeSubject.next(!this.debugModeSubject.value);
  }

  isDebugMode(): boolean {
    return this.debugModeSubject.value;
  }

  handleKeyboardEvent(event: KeyboardEvent): void {
    this.debugModeInputCode += event.key;
    if (this.debugModeInputCode.includes(this.debugModeSecretCode)) {
      console.log('Debug mode toggled');
      this.toggleDebugMode();
      this.debugModeInputCode = ''; // Reset the input code after toggling
    }
    if (this.debugModeInputCode.length > this.debugModeSecretCode.length) {
      this.debugModeInputCode = this.debugModeInputCode.substring(1);
    }
  }
}
